import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import GlobalStyle from "./styles/Global";
import ResetCSS from "./styles/ResetCSS";
import Navbar from "./components/Navbar";
import Footer from './components/Footer';
import {usePrice} from "./store/app/hooks";
import Alerts from './UI/Alert/AlertsWrapper';
import CreateWalletPage from "./pages/CreateWalletPage";
import WalletPage from "./pages/WalletPage";
import RestoreWalletPage from "./pages/RestoreWalletPage";
import {useWalletState} from "./store/wallet/hooks";

function App() {

  usePrice()
  const {walletData} = useWalletState()
  return (
    <>
      <ResetCSS/>
      <GlobalStyle/>
      <Navbar/>
      <Alerts/>
      <div className="app">
        <Routes>
          <Route path="/" element={walletData ? <WalletPage/> : <CreateWalletPage/>}/>
          <Route path="/restore-wallet" element={walletData ? <WalletPage/> : <RestoreWalletPage/>}/>
          <Route path="/wallet" element={walletData ? <WalletPage/> : <Navigate to="/" />}/>
          <Route path="*" element={<Navigate to="/" />}/>
        </Routes>
      </div>
      <Footer/>
    </>
  );
}

export default App;
