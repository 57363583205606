import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24"  fill="none" {...props} >
          <path d="M9 19.5V17.5H15.6L4 5.9L5.4 4.5L17 16.1V9.5H19V19.5H9Z" fill="white"/>
        </Svg>
    );

export default Icon;
