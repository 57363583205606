import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import classes from "../../components/CreateWalletComponents/View/View.module.scss";
import {useWalletAction} from "../../store/wallet/hooks";
import {Button, EButtonVariants} from "../../UI/Button";
import {WalletIcon, WalletLinkIcon} from "../../UI/Svg";


const ButtonStyled = styled(Button)`
  padding: 17px;
  width: 100%;
  max-width: 342px;
  margin-top: 8px;
  height: 78px;
  svg {
    width: 32px;
    height: 32px;
  }
  &:nth-child(2n + 1) {
    margin-top: 60px;
    ${({theme}) => theme.mediaQueries.md} {
      margin-top: 42px;
    }
  }
`

function StepOneView() {
  const {onCreateWallet} = useWalletAction()
  return (
    <div className={[classes.viewTab, classes.active, ''].join(' ')}>
      <div className={[classes.viewTabContent, classes.first].join(' ')}>
        <h2 className={[classes.viewTabContentTitle, classes.first].join(' ')}>
          Create <br/>
          Crypto Wallet
        </h2>
        <p className={[classes.viewTabContentText, classes.first].join(' ')}>
          To create a wallet, click on the button and follow the instructions
        </p>
        <ButtonStyled variant={EButtonVariants.Primary} onClick={() => onCreateWallet()}>
          <WalletLinkIcon/> Create Wallet
        </ButtonStyled>
        <ButtonStyled variant={EButtonVariants.Secondary} as={Link} to="/restore-wallet">
          Restore wallet using phrase
        </ButtonStyled>
      </div>
    </div>
  );
}

export default StepOneView;
