import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {createWallet} from "../../utils/ton";
import {TTonWallet} from "./types";


const fetchRestoreWallet = createAsyncThunk<TTonWallet,
  string[],
  {
    state: RootState
  }>(
  'wallet/fetchRestoreWallet',
  async (mnemonic) => {
    return await createWallet(mnemonic.join(' '))
  }
)

export default fetchRestoreWallet
