import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import useBlockNumber from "../../hooks/useBlockNumber";
import {IAlert} from "../../UI/Alert/types";
import {closeAlert, closeModal, openAlert, openModal} from "./appSlice";
import {EModal} from "./types";
import fetchPrices from "./fetchPrices";

export const getApp = (state: RootState) => state.app

export function useAppAction() {
  const dispatch = useDispatch<AppDispatch>()
  const onFetchPrices = useCallback(() => {
    dispatch(fetchPrices())
  }, [dispatch])

  const onAlertOpen = useCallback((alert: IAlert) => {
    dispatch(openAlert(alert))
  }, [dispatch])
  const onAlertClose = useCallback(() => {
    dispatch(closeAlert())
  }, [dispatch])

  const onModalOpen = useCallback((modal: EModal) => {
    dispatch(openModal(modal))
  }, [dispatch])
  const onModalClose = useCallback((modal: EModal) => {
    dispatch(closeModal(modal))
  }, [dispatch])

  return useMemo(() => ({
    onFetchPrices,
    onAlertOpen,
    onAlertClose,
    onModalOpen,
    onModalClose,
  }), [
    onFetchPrices,
    onAlertOpen,
    onAlertClose,
    onModalOpen,
    onModalClose,
  ])
}

export function useAppState() {
  return useSelector(getApp)
}


export function usePrice() {
  const blockNumber = useBlockNumber()
  const {onFetchPrices} = useAppAction()

  useEffect(() => {
    onFetchPrices()
  }, [onFetchPrices, blockNumber])
}
