import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="31" height="27" viewBox="0 0 31 27" fill="none" {...props} >
          <path fillRule="evenodd" clipRule="evenodd" d="M9.99196 0.762607C9.62954 0.645181 8.9972 0.384103 8.77987 0.0799063C8.69192 -0.0431815 8.66683 -0.0127911 8.57823 0.0945402C8.46271 0.234483 8.23922 0.505225 7.62764 0.73712C4.53521 1.14299 2.08698 3.36309 1.0775 5.11649C-0.72511 8.24735 -1.33633 17.0443 8.10216 18.0836C8.13107 18.087 8.15967 18.09 8.18779 18.0928L8.10216 18.0836C6.07881 17.8501 2.11566 16.2484 1.78853 11.575C1.45672 6.83487 4.81041 4.30675 6.52874 3.63522C7.97056 3.18095 11.3637 2.92419 13.402 5.53129C15.4403 8.13841 15.1203 10.4492 14.7056 11.575L12.6909 16.7892L10.2872 6.99843C10.3431 6.63936 10.6981 6.5385 10.9137 6.5385H7.06225C7.57064 6.60627 7.77612 6.72859 7.91692 6.98774L9.57901 13.1042C9.51693 13.5479 9.38242 14.136 9.14106 14.4054C9.0238 14.5362 9.06461 14.557 9.20874 14.6302C9.41143 14.7332 9.81847 14.9401 10.2776 15.7031L10.2914 15.726L12.3354 23.2477L16.3054 13.9451L19.2088 21.6479C19.8605 23.228 21.9699 26.3881 25.1933 26.3881C28.4166 26.3881 30.4074 23.702 31 22.359C30.1112 23.2873 27.7055 24.9779 25.1933 24.3143C24.0046 24.0003 23.1758 23.3202 22.6178 22.5778C21.6178 21.2472 21.8435 19.4561 22.2439 17.8406L22.9044 15.1749C23.1676 14.8058 23.6293 14.2571 24.0131 14.1248C24.1792 14.0675 24.1537 14.0296 24.0634 13.8954C23.9365 13.7068 23.6815 13.328 23.6518 12.438C23.6487 12.3473 23.6429 12.278 23.6345 12.2281L24.8574 7.29292C25.0253 6.81361 25.1604 6.62771 25.8455 6.5385H21.994C22.1891 6.5385 22.4981 6.61911 22.5967 6.89406H22.5861L20.0975 17.1447C20.0666 16.9911 20.0328 16.8221 19.9963 16.6396C19.4037 13.6791 18.1035 7.18337 16.8979 5.53129C15.4569 3.55652 13.6312 1.24524 9.99196 0.762607Z" fill="white"/>
        </Svg>
    );

export default Icon;
