import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {IAsset} from "../../config/assets";
import {useAppState} from "../../store/app/hooks";

const Wrapper = styled.div`
  min-width: 235px;
  width: 235px;
  height: 229px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  background: ${({theme}) => theme.colors.card};
  backdrop-filter: blur(57px);
  padding: 24px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
`

const IconWrapper = styled.div`
  width: 58px;
  min-width: 58px;
  height: 58px;

  img {
    width: auto;
    height: 100%;
  }
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
`

const Title = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin-top: 8px;
  line-height: 80%; /* 17.6px */
  letter-spacing: -0.88px;
`

const SymbolBlock = styled.div`
  color: ${({theme}) => theme.colors.border};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`

const BalanceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const ChangeValue = styled.div<{ isUp: boolean }>`
  color: ${({isUp}) => isUp ? '#49F29E' : '#f24949'};
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1px;
`

function FundComponent({
                         fund: {name, balance, change, decimals, logoURI, symbol, chainId},
                         onClick,
                         ...props
                       }: { fund: IAsset, onClick?: (args: any) => void }) {
  const [usdBalance, setUsdBalance] = useState(0);

  const {usdPrices} = useAppState()

  useEffect(() => {
    if (symbol in usdPrices && balance) {
      setUsdBalance(usdPrices[symbol] ? parseFloat((balance * usdPrices[symbol]).toFixed(4)) : 0)
    }
    // getNativeToUSD((balance || 0), chainId).then((res) => setUsdBalance(+res.toFixed(4)))
  }, [balance, symbol, usdPrices])
  return (
    <Wrapper {...props} onClick={onClick}>
      <Header>
        <IconWrapper>
          <img src={logoURI} alt=""/>
        </IconWrapper>
        <TitleBlock>
          <Title>{name}</Title>
          <SymbolBlock>{symbol}</SymbolBlock>
        </TitleBlock>
      </Header>
      <BalanceBlock>
        <Title>{balance ? balance.toFixed(4) : 0} {symbol}</Title>
        <SymbolBlock>
          ${usdBalance}

          <ChangeValue isUp={change >= 0}>
            {change}%
          </ChangeValue>
        </SymbolBlock>
      </BalanceBlock>
    </Wrapper>
  );
}

export default FundComponent;
