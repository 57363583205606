import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props} >
          <path d="M12.3654 19.3667L20.5904 11.1417L18.957 9.50837L12.3654 16.1L9.04037 12.775L7.40703 14.4084L12.3654 19.3667ZM13.9987 25.6667C12.3848 25.6667 10.8681 25.3605 9.4487 24.748C8.02925 24.1355 6.79453 23.3042 5.74453 22.2542C4.69453 21.2042 3.86328 19.9695 3.25078 18.55C2.63828 17.1306 2.33203 15.6139 2.33203 14C2.33203 12.3862 2.63828 10.8695 3.25078 9.45004C3.86328 8.0306 4.69453 6.79587 5.74453 5.74587C6.79453 4.69587 8.02925 3.86462 9.4487 3.25212C10.8681 2.63962 12.3848 2.33337 13.9987 2.33337C15.6126 2.33337 17.1293 2.63962 18.5487 3.25212C19.9681 3.86462 21.2029 4.69587 22.2529 5.74587C23.3029 6.79587 24.1341 8.0306 24.7466 9.45004C25.3591 10.8695 25.6654 12.3862 25.6654 14C25.6654 15.6139 25.3591 17.1306 24.7466 18.55C24.1341 19.9695 23.3029 21.2042 22.2529 22.2542C21.2029 23.3042 19.9681 24.1355 18.5487 24.748C17.1293 25.3605 15.6126 25.6667 13.9987 25.6667Z" fill="#4CBFFF"/>
        </Svg>
    );

export default Icon;
