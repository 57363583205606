import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="33" height="33" viewBox="0 0 33 33" fill="none" {...props} >
          <path d="M16.5 23.375C16.8896 23.375 17.2161 23.2432 17.4797 22.9797C17.7432 22.7161 17.875 22.3896 17.875 22C17.875 21.6104 17.7432 21.2839 17.4797 21.0203C17.2161 20.7568 16.8896 20.625 16.5 20.625C16.1104 20.625 15.7839 20.7568 15.5203 21.0203C15.2568 21.2839 15.125 21.6104 15.125 22C15.125 22.3896 15.2568 22.7161 15.5203 22.9797C15.7839 23.2432 16.1104 23.375 16.5 23.375ZM15.125 17.875H17.875V9.625H15.125V17.875ZM16.5 30.25C14.5979 30.25 12.8104 29.8891 11.1375 29.1672C9.46458 28.4453 8.00938 27.4656 6.77187 26.2281C5.53437 24.9906 4.55469 23.5354 3.83281 21.8625C3.11094 20.1896 2.75 18.4021 2.75 16.5C2.75 14.5979 3.11094 12.8104 3.83281 11.1375C4.55469 9.46458 5.53437 8.00938 6.77187 6.77187C8.00938 5.53437 9.46458 4.55469 11.1375 3.83281C12.8104 3.11094 14.5979 2.75 16.5 2.75C18.4021 2.75 20.1896 3.11094 21.8625 3.83281C23.5354 4.55469 24.9906 5.53437 26.2281 6.77187C27.4656 8.00938 28.4453 9.46458 29.1672 11.1375C29.8891 12.8104 30.25 14.5979 30.25 16.5C30.25 18.4021 29.8891 20.1896 29.1672 21.8625C28.4453 23.5354 27.4656 24.9906 26.2281 26.2281C24.9906 27.4656 23.5354 28.4453 21.8625 29.1672C20.1896 29.8891 18.4021 30.25 16.5 30.25ZM16.5 27.5C19.5708 27.5 22.1719 26.4344 24.3031 24.3031C26.4344 22.1719 27.5 19.5708 27.5 16.5C27.5 13.4292 26.4344 10.8281 24.3031 8.69688C22.1719 6.56563 19.5708 5.5 16.5 5.5C13.4292 5.5 10.8281 6.56563 8.69688 8.69688C6.56563 10.8281 5.5 13.4292 5.5 16.5C5.5 19.5708 6.56563 22.1719 8.69688 24.3031C10.8281 26.4344 13.4292 27.5 16.5 27.5Z" fill="#FF4418"/>
        </Svg>
    );

export default Icon;
