import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...props} >
            <g opacity="0.2">
              <path d="M9.5 18.5C8.95 18.5 8.47917 18.3042 8.0875 17.9125C7.69583 17.5208 7.5 17.05 7.5 16.5V4.5C7.5 3.95 7.69583 3.47917 8.0875 3.0875C8.47917 2.69583 8.95 2.5 9.5 2.5H18.5C19.05 2.5 19.5208 2.69583 19.9125 3.0875C20.3042 3.47917 20.5 3.95 20.5 4.5V16.5C20.5 17.05 20.3042 17.5208 19.9125 17.9125C19.5208 18.3042 19.05 18.5 18.5 18.5H9.5ZM5.5 22.5C4.95 22.5 4.47917 22.3042 4.0875 21.9125C3.69583 21.5208 3.5 21.05 3.5 20.5V6.5H5.5V20.5H16.5V22.5H5.5Z" fill="#080808"/>
            </g>
        </Svg>
    );

export default Icon;
