import { createGlobalStyle } from 'styled-components'
import { WednesdayTheme } from '../theme'
import './_fonts.scss'
import noise from '../assets/images/noise.webp'

declare module 'styled-components' {
	export interface DefaultTheme extends WednesdayTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    transition: all .25s ease;
    color: ${({ theme }) => theme.colors.white};
  }

  html {
    scroll-behavior: smooth;
  }
  html, body, #root {
    min-width: 375px !important;
  
    
  }
  body {
    background: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #root {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .app {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .noise {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(${noise});
    z-index: 20;
    opacity: .3;
    mix-blend-mode: overlay;
    pointer-events: none;
    font-size: 0;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-initialized .slick-slide {
    display: flex;
    height: unset;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    & > div {
      height: 100%;
    }
  }
	* {
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}

		&::-webkit-scrollbar-track {
		}

		&::-webkit-scrollbar-thumb {
			background-color: #000;
			border-radius: 8px;
			width: 6px;
			//height: 6px;
		}
	}
`

export default GlobalStyle
