import {AlertTheme, EAlertVariants} from "./types";
import {darkColors} from "../../theme/colors";

export const dark: AlertTheme = {
  [EAlertVariants.Success]: {
    background: darkColors.alertBg,
    icon: darkColors.iconSuccess,
    text: darkColors.black
  },
  [EAlertVariants.Error]: {
    background: darkColors.alertBgError,
    icon: darkColors.down,
    text: darkColors.white
  },
  [EAlertVariants.Info]: {
    background: darkColors.alertBg,
    icon: darkColors.iconBg,
    text: darkColors.black
  },
};
