const contacts: Array<{icon: string, href: string}> = [
    {
        icon: 'TelegramIcon',
        href: 'https://t.me/wednesdayinu_token',
    },
    {
        icon: 'TwitterIcon',
        href: 'https://twitter.com/WednesdayInu',
    },
    {
        icon: 'MediumIcon',
        href: 'https://coinmarketcap.com/currencies/wednesday-inu/',
    },
    {
        icon: 'RedditIcon',
        href: 'https://www.coingecko.com/en/coins/wednesday-inu',
    },
    {
        icon: 'UniswapIcon',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xddBb3E6F8413D0E3ADc700a731DA304aeC97bCBb',
    },
]

const socials: Array<{icon: string, href: string}> = [
    // {
    //     icon: 'InstagramIcon',
    //     href: '/',
    // },
    {
        icon: 'TwitterIcon',
        href: 'https://twitter.com/AddamsAiETH',
    },
    {
        icon: 'TelegramIcon',
        href: 'https://t.me/addamsai',
    },
    // {
    //     icon: 'DiscordIcon',
    //     href: '/',
    // },
    // {
    //     icon: 'TiktokIcon',
    //     href: '/',
    // },
]

export {contacts, socials}
