import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAlert} from "../../UI/Alert/types";
import {EModal} from "./types";
import fetchPrices from "./fetchPrices";

export interface AppState {
  usdPrices: {
    [coin: string]: number
  }
  alert: IAlert[],
  modal: { [modal in EModal]: boolean }
}

const modalInit: { [modal in EModal]: boolean } = {
  [EModal.Output]: false,
  [EModal.Deposit]: false,
  [EModal.Token]: false,
  [EModal.Exchange]: false,
}

export const initialState: AppState = {
  usdPrices: {
  },
  alert: [],
  modal: modalInit
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openAlert: (state, action: PayloadAction<IAlert>) => {
      state.alert = state.alert ? [...state.alert, action.payload] : [action.payload]
    },
    closeAlert: state => {
      state.alert = state.alert.length > 1 ? state.alert.slice(1): []
    },
    openModal: (state, action: PayloadAction<EModal>) => {
      if (state.modal) {
        state.modal[action.payload] = true
      } else {
        state.modal = {
          ...modalInit,
          [action.payload]: true
        }
      }
    },
    closeModal: (state, action: PayloadAction<EModal>) => {
      if (state.modal) {
        state.modal[action.payload] = false
      } else {
        state.modal = {
          ...modalInit,
          [action.payload]: false
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPrices.fulfilled, (state, action: PayloadAction<{ [coin: string]: number }>) => {
        state.usdPrices = {
          ...state.usdPrices,
          ...action.payload
        }
      })
  }
})

export const {openAlert, closeAlert, closeModal, openModal} = appSlice.actions
export default appSlice.reducer
