import React from 'react';
import styled from "styled-components";
import logo from "../../assets/images/Logo.webp";
import {socials} from "../../config/socials";
import * as IconModule from "../../UI/Svg";
import {SvgProps} from "../../UI/Svg";
import {Button, EButtonVariants} from "../../UI/Button";
import {Container} from "../Templates";

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> };

const ContainerStyled = styled(Container)`
  justify-content: space-between;
  margin-top: 16px;
  z-index: 1;

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 72px;
  }
`

const CopyrightBlock = styled(Container)`
  margin-top: 15px;
  flex-direction: column;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 49px;
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 38px;
  z-index: 1;
`

const CopyrightText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: ${({theme}) => theme.colors.white};
  opacity: 0.6;
  white-space: nowrap;
  width: 100%;
  @media (min-width: 768px) {
    width: unset;
  }
`

const CopyrightLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: ${({theme}) => theme.colors.white};
`


const Socials = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 6px;
`

const LeftBlock = styled.div`
  display: none;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 26px;
  @media (min-width: 768px) {
    display: flex;
  }
`

const LeftBlockMobile = styled(LeftBlock)`
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (min-width: 768px) {
    display: none;
  }
`

const SocialItem = styled.a`
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-color: #1B1B1F;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`
const ButtonStyled = styled(Button)`
  padding: 14px 20px;
  gap: 11px;
  font-size: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
`
const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16.57px;
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Title = styled.h3`
  font-size: 28.728px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`
const LogoImg = styled.img`
  width: 58.562px;
  height: 59.98px;
`

function Footer() {
  return (
    <>
      <ContainerStyled>
        <LogoBlock>
          <LogoImg src={logo} alt='Addams'/>
          <TitleBlock>
            <Title>$ADDAMS</Title>
          </TitleBlock>
        </LogoBlock>
        <LeftBlock>
          <Socials>
            {
              socials.map((item, id) => {
                const Icon = Icons[item.icon];
                const iconElement = <Icon/>;
                return (<SocialItem key={`item-${id}`} href={item.href} target='_blank'
                                    rel='noreferrer'>{iconElement}</SocialItem>)
              })
            }
          </Socials>
        </LeftBlock>
      </ContainerStyled>
      <CopyrightBlock>
        <CopyrightText>Copyright {(new Date()).getFullYear()}</CopyrightText>
        <Links>
          <CopyrightLink href='/' target='_blank' rel='noreferrer'>Political Confidence</CopyrightLink>
          <CopyrightLink href='/' target='_blank' rel='noreferrer'>Private Policy</CopyrightLink>
        </Links>
      </CopyrightBlock>

      <LeftBlockMobile>
        <Socials>
          {
            socials.map((item, id) => {
              const Icon = Icons[item.icon];
              const iconElement = <Icon/>;
              return (<SocialItem key={`item-mobile-${id}`} href={item.href} target='_blank'
                                  rel='noreferrer'>{iconElement}</SocialItem>)
            })
          }
        </Socials>
      </LeftBlockMobile>
    </>
  );
}

export default Footer;
