import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props} >
            <path d="M25.6745 33.9565V28.9872H19.0488V12.423H14.0796V17.3923H4.14105V5.79736H14.0796V10.7666H25.6745V5.79736H35.613V17.3923H25.6745V12.423H20.7052V27.3308H25.6745V22.3615H35.613V33.9565H25.6745ZM27.3309 15.7359H33.9566V7.45378H27.3309V15.7359ZM27.3309 32.3H33.9566V24.018H27.3309V32.3ZM5.79747 15.7359H12.4231V7.45378H5.79747V15.7359Z" fill="white"/>
        </Svg>
    );

export default Icon;
