import {Cell, JettonMaster, TonClient, WalletContractV3R2} from "@ton/ton";

import {mnemonicToWalletKey} from "@ton/crypto";

import {Address, beginCell, fromNano, internal, toNano} from "@ton/core";
import TonWeb from "tonweb";
import dayjs from "dayjs";
import {sleep} from "./index";
import {ITransaction, TTonWallet} from "../store/wallet/types";


const endpoint = 'https://testnet.toncenter.com/api/v2/jsonRPC'
const apiKey = '11a00290b2147b96962c559fae6476a8dc83288b2b5146351bd9ccadb3103da2'

const client = new TonClient({endpoint, apiKey});

const getWalletFromPubKey = (publicKey: any) => {

    // Создание кошелька на основе публичного ключа
    return WalletContractV3R2.create({publicKey, workchain: 0})
}

export const getBalanceTon = async (wallet: string) => {
    try {
        const balance = await client.getBalance(Address.parse(wallet))
        return +fromNano(balance);
    } catch (e) {
        console.log(e);
        return 0
    }
}
export const createWallet = async (mnemonic: string) => {
    const password = null;
    const keyPair = await mnemonicToWalletKey(mnemonic.split(' '), password);

    const {publicKey, secretKey: privateKey} = keyPair;


    const wallet = getWalletFromPubKey(publicKey)

    return {
        address: wallet.address.toString(),
        privateKey: Buffer.from(privateKey).toString('hex'),
        publicKey: Buffer.from(publicKey).toString('hex')
    }
}

export async function calculateTransfer(senderWallet: TTonWallet, recipientAddress: Address, amountString: string) {

    try {
        const wallet = getWalletFromPubKey(Buffer.from(senderWallet.publicKey, 'hex'));

        const balance = await client.getBalance(wallet.address);
        console.log(`Баланс: ${balance.toString()} нанотонов`);

        let seqno = 0;
        if (balance > 0) {
            seqno = await wallet.getSeqno(client.provider(wallet.address));
            console.log(`Seqno: ${seqno}`);
        } else {
            console.log('Кошелек новый и еще не активен. Пополните его для отправки транзакций.');
        }
        const amount = toNano(amountString); // пример: 0.01 TON
        const transfer = wallet.createTransfer({
            seqno: seqno || 0,
            sendMode: 3, // Отправить средства и завершить
            secretKey: Buffer.from(senderWallet.privateKey, 'hex'),
            // secretKey: TonWeb.utils.hexToBytes(senderWallet.privateKey),
            messages: [
                internal({
                    to: recipientAddress,
                    value: amount,
                    bounce: false,
                    body: null, // Необязательно: можно добавить полезную нагрузку (например, комментарий)
                },)
            ],
        })
        // @ts-ignore
        const estimatedFee = await client.estimateExternalMessageFee(wallet.address, {body: transfer})
        // Оценка комиссии за транзакцию
        const inFwdFee = estimatedFee.source_fees.in_fwd_fee;
        const storageFee = estimatedFee.source_fees.storage_fee;
        const gasFee = estimatedFee.source_fees.gas_fee;
        const fwdFee = estimatedFee.source_fees.fwd_fee;
        // Суммируем все комиссии
        return BigInt(inFwdFee + storageFee + gasFee + fwdFee)

    } catch (error) {
        console.log("Failed to transfer Ton", error);
    }
}
export async function createTransfer(senderWallet: TTonWallet, recipientAddress: Address, amountString: string) {
    try {
        const wallet = getWalletFromPubKey(Buffer.from(senderWallet.publicKey, 'hex'));
        const contract = client.open({...wallet, address: Address.parse(senderWallet.address)});

        const balance = await client.getBalance(wallet.address);
        console.log(`Баланс: ${balance.toString()} нанотонов`);

        let seqno = 0;
        if (balance > 0) {
            seqno = await wallet.getSeqno(client.provider(wallet.address));
            console.log(`Seqno: ${seqno}`);
        } else {
            console.log('Кошелек новый и еще не активен. Пополните его для отправки транзакций.');
        }
        const amount = toNano(amountString); // пример: 0.01 TON
        const transfer = wallet.createTransfer({
            seqno: seqno || 0,
            sendMode: 3, // Отправить средства и завершить
            secretKey: Buffer.from(senderWallet.privateKey, 'hex'),
            messages: [
                internal({
                    to: recipientAddress,
                    value: amount,
                    bounce: false,
                    body: null, // Необязательно: можно добавить полезную нагрузку (например, комментарий)
                },)
            ],
        })
        // @ts-ignore
        const estimatedFee = await client.estimateExternalMessageFee(wallet.address, {body: transfer})
        // Оценка комиссии за транзакцию
        const inFwdFee = estimatedFee.source_fees.in_fwd_fee;
        const storageFee = estimatedFee.source_fees.storage_fee;
        const gasFee = estimatedFee.source_fees.gas_fee;
        const fwdFee = estimatedFee.source_fees.fwd_fee;
        // Суммируем все комиссии
        const totalFeeInNano = inFwdFee + storageFee + gasFee + fwdFee;

        // Преобразуем сумму комиссии в TON
        const totalFeeInTon = fromNano(totalFeeInNano);
        console.log('Оценка комиссии:', totalFeeInTon);

        await client.sendExternalMessage(wallet, transfer);

        console.log(`Отправлено ${amountString} TON на адрес ${recipientAddress}`);
        // После отправки транзакции запросим список последних транзакций

        // Ищем транзакцию с соответствующим seqno
        let sentTransactionHash = null
        const currentTime = Math.floor(Date.now() / 1000);
        await sleep(1000 * 5)
        while (sentTransactionHash === null) {
            try {
                const transactions = await client.getTransactions(wallet.address, {limit: 1});
                const tx = transactions[0]
                if (tx) {
                    const transactionTime = tx.now;
                    if (transactionTime >= currentTime) {
                        console.log('tx', tx)
                        sentTransactionHash = tx.hash().toString('hex')
                    }
                }
                if (sentTransactionHash === null) {
                    await sleep(2000)
                }
            } catch (e) {
                console.log(`Receipt error:`, e);
                break;
            }
        }

        console.log(`Hash транзакции: ${sentTransactionHash}`);
        console.log(`Ссылка на Tonscan: https://testnet.tonviewer.com/transaction/${sentTransactionHash}`);
        return {
            hash: sentTransactionHash,
            success: true
        }

    } catch (error) {
        console.log("Failed to transfer Ton", error);
        return {
            hash: '',
            success: false
        }
    }
}

export const getTonTransactions = async (userWallet: TTonWallet): Promise<ITransaction[]> => {
    try {
        const transactions = await client.getTransactions(Address.parse(userWallet.address), {limit: 10});

        const data: ITransaction[] = []

        for (const tx of transactions) {
            if (tx.inMessage.info.type === 'internal') {
                const message: any = tx.inMessage
                if (message) {
                    const sender = message.info.dest; // Адрес получателя
                    const value = fromNano(message.info.value.coins); // Сумма перевода
                    const date = new Date(message.info.createdAt * 1000); // Сумма перевода

                    data.push({
                        transactionType: 'deposit',
                        client: sender.toString(),
                        value: value.toString(),
                        date: dayjs(date).locale('en').format('D MMM YYYY'),
                        timestamp: date.getTime(),
                        symbol: 'TON',
                        hash: tx.hash().toString('hex'),
                        chainId: 0,
                    })
                }
            } else {
                const messagesKeys = tx.outMessages.keys()
                for (const messagesKey of messagesKeys) {
                    const message: any = tx.outMessages.get(messagesKey)
                    if (message) {
                        const recipient = message.info.dest; // Адрес получателя
                        const value = fromNano(message.info.value.coins); // Сумма перевода
                        const date = new Date(message.info.createdAt * 1000); // Сумма перевода

                        data.push({
                            transactionType: 'withdraw',
                            client: recipient.toString(),
                            value: value.toString(),
                            date: dayjs(date).locale('en').format('D MMM YYYY'),
                            timestamp: date.getTime(),
                            symbol: 'TON',
                            hash: tx.hash().toString('hex'),
                            chainId: 0,
                        })
                    }
                }
            }
        }
        return data
    } catch (e) {
        console.log(e);
        return []
    }
}

export const getJettonWalletAddress = async (_ownerAddr: string, _jettonMasterAddress: string) => {
    const jettonMasterAddress = Address.parse(_jettonMasterAddress) // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
    const userAddress = Address.parse(_ownerAddr)

    const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress))
    const jettonWalletAddres = await jettonMaster.getWalletAddress(userAddress)
    console.log('Jetton Wallet Address:', jettonWalletAddres)
    return jettonWalletAddres.toString()
}
export const getJettonBalance = async (userWallet: string, jettonAddress: string) => {
    const jettonWalletAddress = await getJettonWalletAddress(userWallet, jettonAddress)
    const tokenContractAddress = Address.parse(jettonWalletAddress); // Укажите адрес контракта токена

    const {stack} = await client.runMethod(tokenContractAddress, 'get_wallet_data');
    return fromNano(stack.readBigNumber())
}

const generateQueryId = () => {
    return BigInt(Date.now()); // Время в миллисекундах как BigInt
};
const createTransferPayload2 = (userWallet: string, recipientAddr: string, jettonAmount: bigint, forwardAmount: bigint) => {
    const destinationAddress = Address.parse(recipientAddr)
    const queryId = generateQueryId();
    const forwardPayload = beginCell()
        .storeUint(0, 32) // 0 opcode means we have a comment
        .storeStringTail(`Send from wallet! ${queryId}`)
        .endCell();


    const transferPayload = beginCell()
        .storeUint(0x0f8a7ea5, 32) // opcode for jetton transfer
        .storeUint(queryId, 64) // query id
        .storeCoins(jettonAmount) // jetton amount, amount * 10^9
        .storeAddress(destinationAddress)
        // .storeAddress(destinationAddress) // response destination
        .storeAddress(Address.parse(userWallet)) // response destination
        .storeBit(0) // no custom payload
        .storeCoins(forwardAmount) // forward amount - if >0, will send notification message
        // .storeBit(0) // we store forwardPayload as a reference
        .storeBit(1) // we store forwardPayload as a reference
        .storeRef(forwardPayload)
        .endCell();

    return transferPayload;
};


export const sendJettons = async (userWallet: TTonWallet, jettonMasterAddress: string, recipientAddress: string, amountString: string) => {

    try {
        const senderWallet = getWalletFromPubKey(Buffer.from(userWallet.publicKey, 'hex'));

        const transferAmount = toNano(amountString);
        const forwardAmount = toNano('0.001');

        const balance = await client.getBalance(Address.parse(userWallet.address));
        console.log(`Баланс: ${balance.toString()} нанотонов`);

        let seqno = 0;
        if (balance > 0) {
            seqno = await senderWallet.getSeqno(client.provider(Address.parse(userWallet.address)));
            console.log(`Seqno: ${seqno}`);
        } else {
            console.log('Кошелек новый и еще не активен. Пополните его для отправки транзакций.');
        }
        const payload = createTransferPayload2(userWallet.address, recipientAddress, transferAmount, forwardAmount);

        const internalMessage = internal({
            to: jettonMasterAddress,
            value: toNano('0.2'),
            bounce: true,
            body: payload
        });

        const transfer = senderWallet.createTransfer({
            seqno: seqno || 0,
            sendMode: 3,
            secretKey: Buffer.from(userWallet.privateKey, 'hex'),
            messages: [
                internalMessage
            ],
        })
        await client.sendExternalMessage(senderWallet, transfer);

        // @ts-ignore
        const estimatedFee = await client.estimateExternalMessageFee(userWallet.address, {body: transfer})
        // Оценка комиссии за транзакцию
        const inFwdFee = estimatedFee.source_fees.in_fwd_fee;
        const storageFee = estimatedFee.source_fees.storage_fee;
        const gasFee = estimatedFee.source_fees.gas_fee;
        const fwdFee = estimatedFee.source_fees.fwd_fee;
        // Суммируем все комиссии
        const totalFeeInNano = inFwdFee + storageFee + gasFee + fwdFee;

        // Преобразуем сумму комиссии в TON
        const totalFeeInTon = fromNano(totalFeeInNano);
        console.log('Оценка комиссии:', totalFeeInTon);

        await client.sendExternalMessage(senderWallet, transfer);

        console.log('Транзакция отправлена:');
        // Ищем транзакцию с соответствующим seqno
        let sentTransactionHash = null
        const currentTime = Math.floor(Date.now() / 1000);
        await sleep(1000 * 5)
        while (sentTransactionHash === null) {
            try {
                const transactions = await client.getTransactions(Address.parse(userWallet.address), {limit: 1});
                const tx = transactions[0]
                if (tx) {
                    const transactionTime = tx.now;
                    if (transactionTime >= currentTime) {
                        sentTransactionHash = tx.hash().toString('hex')
                    }
                }
                if (sentTransactionHash === null) {
                    await sleep(2000)
                }
            } catch (e) {
                console.log(`Receipt error:`, e);
                break;
            }
        }
        console.log(`Hash транзакции: ${sentTransactionHash}`);
        console.log(`Ссылка на Tonscan: https://testnet.tonscan.org/transaction/${sentTransactionHash}`);
        return {hash: sentTransactionHash, success: true}
    }
    catch (e) {
        console.log(e);
        return {hash: '', success: false}
    }
}
// async function test() {
//     const wallet1 = await createWallet(mnemonic1)
//     // const wallet2 = await createWallet(mnemonic2)
//
//     // const wallet1 = await getWalletFromPubKey(walletData1.publicKey)
//     // const wallet2 = await createWallet(mnemonic2)
//     // const balance = await client.getBalance(wallet1.address)
//     // console.log(balance)
//     const amount = '0.01'
//     // const history = await tonweb.getTransactions(walletData1.address);
//     // console.log('history', history)
//     // await createTransfer(wallet1, wallet2.address, amount)
//     // await createTransfer(wallet1, 'UQCTiKBXUgJzkb40bux6JJ_6kWMlBXtowab1OVEXTvlWy0Wz', amount)
//
//     // await getTransactions(wallet1)
//
//
//
//     const jettonAddress = 'kQC1tu5iJt2rJf9E9ZmibiI3Gdd8T8S5A_A4bal2xH2IcoUX'
//     // const jettonAddress = 'kQCZj11y_w-X5ZhrrKthhHITfHsX52ti_mNz4XxG-gU8phqy'
//     const amountJetton = '110'
//
//     const recipient = Address.parse('0QARpbf4ZN71vWvcwRPSNop7hyXx7KE1APuXhg0tha3Qey4u')
//     // const recipientJettonWallet = await getJettonWalletAddress(recipient,jettonAddress)
//     const recipientJettonWallet = 'kQApHYw8tsQXruFlDxb9UR1ABZnDbTMd9UNesMLszEVPqkel'
//     // await getJettonWalletAddress(wallet1.address, jettonAddress)
//     // await getJettonWalletAddress2(wallet1.address, jettonAddress)
//     // await testJettons(wallet1, jettonAddress)
//     await testSendJettons(wallet1, jettonAddress, recipientJettonWallet, amountJetton)
// }
