import {configureStore} from '@reduxjs/toolkit'
import {save, load} from 'redux-localstorage-simple'
import appSlice, {AppState, initialState as appInitialState} from './app/appSlice'
import walletSlice, {WalletState, initialState as walletInitialState} from './wallet/walletSlice'

type MergedState = {
  wallet: WalletState
  app: AppState
}
const PERSISTED_KEYS: string[] = ['wallet.walletData',
  'app.usdPrices',
  'wallet.transactions',
  // 'wallet.assets'
]
const NAMESPACE_STORE: string = 'addams_wallet_local'
const loadedState = load({
  states: PERSISTED_KEYS, namespace: NAMESPACE_STORE, preloadedState: {
    wallet: {...walletInitialState},
    // app: {...appInitialState}
  }
},) as MergedState

export const store = configureStore({
  reducer: {
    app: appSlice,
    wallet: walletSlice,
  },
  devTools: true,
  preloadedState: loadedState,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    thunk: true,
  })
    .concat([save({states: PERSISTED_KEYS, namespace: NAMESPACE_STORE})])
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
