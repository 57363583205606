import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props} >
          <path d="M10.6709 13.7197L6.09766 9.14648L10.6709 4.57324L11.738 5.64033L8.23184 9.14648L11.738 12.6526L10.6709 13.7197Z" fill="white"/>
        </Svg>
    );

export default Icon;
