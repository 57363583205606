import styled from "styled-components";
import {ButtonProps} from "./types";


const Button = styled.button<ButtonProps>`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 23px 41px;
  border: none; 
  outline: none;
  background: ${({theme, variant}) => theme.button[variant].background};
  text-align: center;
  color: ${({theme, variant}) => theme.button[variant].color};
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  border-radius: 12px;
  width: fit-content;
  & > * {
    z-index: 1;
  }


  &:not(:disabled):hover {
    svg path {
    }
  }

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
  ${({theme}) => theme.mediaQueries.md} {
  }
`;

export default Button
