import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import _ from "lodash";
import classes from "../../components/CreateWalletComponents/View/View.module.scss";
import {useWalletAction, useWalletState} from "../../store/wallet/hooks";
import {addZeroForward, generateArrayFrom} from "../../utils";
import {Button, EButtonVariants} from "../../UI/Button";
import {ArrowNextIcon} from "../../UI/Svg";

const InputWord = styled.input`
  background: transparent;
  width: 100px;
  border: none;
  outline: none;
`

const ButtonStyled = styled(Button)`
  max-width: 342px;
  padding: 20px 60px;
  width: fit-content;
  margin-top: 48px;
  span {
    line-height: inherit;
    font-size: 18px;

    background: transparent;
    background: -webkit-linear-gradient(#4CA9FF, #4C89FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
  }
`
function StepThirdView() {

  const {mnemonic} = useWalletState()
  const {onConfirmWallet} = useWalletAction()

  const words = useMemo(() => mnemonic.split(' '), [mnemonic])
  const [isValid, setIsValid] = useState<boolean>(false);
  const checkedIndexes = useMemo(() => {
    const arr = _.shuffle(generateArrayFrom(0, 12)).slice(0, 3)
    const res = arr.sort()
    return res
  }, [])

  const [checkedItems, setCheckedItems] = useState<{ [index: number]: string }>({});

  const onChange = (index: number, value: string) => setCheckedItems(prevState => ({...prevState, [index]: value}))

  useEffect(() => {
    for (const index of checkedIndexes) {
      if (words[index] !== checkedItems[index]) {
        setIsValid(false)
        break
      }
      setIsValid(true)
    }
  }, [checkedIndexes, words, checkedItems])


  const onKeyHandler = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      const currentId = event.currentTarget.id
      const id = parseInt(currentId.split('-')[1], 10)
      if (id < 2) {
        const nextElement = document.getElementById(`input-${id + 1}`)
        nextElement.focus()
      } else if (isValid) {
        onConfirmWallet()
      }
    } else if (event.key === "Delete" || event.keyCode === 46 ) {
      setCheckedItems({})
    }
  }, [onConfirmWallet])


  return (
    <div className={[classes.viewTab, classes.three, classes.active, ''].join(' ')}>
      <div className={[classes.viewTabContent, ''].join(' ')}>
        <h2 className={[classes.viewTabContentTitle, ''].join(' ')}>
          Checking the <br/> Seed phrase
        </h2>
        <p className={[classes.viewTabContentText, ''].join(' ')}>
          Now choose the correct sequence so that we can understand that you have actually memorized it
        </p>
        <div className={[classes.viewTabContentRow, ''].join(' ')}>
          {checkedIndexes.map((index) => {
              return (
                <div
                  key={index}
                  className={[classes.viewTabContentRowWord, ''].join(' ')}
                >
                  <span>{addZeroForward((index + 1).toString())}</span>
                  <InputWord id={`input-${index}`} onKeyDown={onKeyHandler} value={checkedItems[index] || ''} onChange={event => onChange(index, event.target.value)}/>
                </div>
              )
            }
          )}
        </div>
        <ButtonStyled disabled={!isValid} variant={EButtonVariants.White} onClick={onConfirmWallet}>
          {isValid ? <>
            <span>Next</span>
            <ArrowNextIcon/>
          </> : <span>No match!</span>}
        </ButtonStyled>

      </div>
    </div>
  );
}

export default StepThirdView;
