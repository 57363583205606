import React  from 'react'
import classes from './View.module.scss'
import StepOneView from "../../../views/WalletView/StepOneView";
import {useWalletState} from "../../../store/wallet/hooks";
import StepSecondView from "../../../views/WalletView/StepSecondView";
import StepThirdView from "../../../views/WalletView/StepThirdView";
import { CheckIcon } from '../../../UI/Svg';

function View() {

	const {step} = useWalletState()

	return (
		<div className={[classes.view, ''].join(' ')}>
			<div className={[classes.viewTop, ` color${step}`].join(' ')}>
				<div className={[classes.viewTopColumn, ''].join(' ')}>
					<span>{step > 1 ? <><CheckIcon/><CheckIcon/></> : '01'}</span>
					Creating a wallet
				</div>
				<div className={[classes.viewTopLine, ''].join(' ')}>0</div>
				<div className={[classes.viewTopColumn, ''].join(' ')}>
					<span>{step > 2 ? <><CheckIcon/><CheckIcon/></> : '02'}</span>
					Creating a seed phrase
				</div>
				<div className={[classes.viewTopLine, ''].join(' ')}>0</div>
				<div className={[classes.viewTopColumn, ''].join(' ')}>
					<span>03</span>
					Phrase check
				</div>
			</div>
			{step === 1 && <StepOneView/>}
			{step === 2 && <StepSecondView/>}
			{step === 3 && <StepThirdView/>}
		</div>
	)
}

export default View
