import React, { useState } from 'react'
import styled from 'styled-components'
import Container from '../components/Templates/Container'
import bg from '../assets/images/BGCreate.png'
import mainImg from '../assets/images/MainImage.webp'
import secondImg from '../assets/images/SecondImage.webp'
import View from '../components/CreateWalletComponents/View/View'
import {useWalletState} from "../store/wallet/hooks";

const Wrapper = styled.div`
	padding-top: 100px;
	display: block;
	text-align: left;
	background: url("${bg}") no-repeat -26px 96px;
	background-size: 1500px auto;

	${({theme}) => theme.mediaQueries.md} {
		padding-top: 0;
		background-size: 856px auto;
		background-position: 20px 70px;
	}
`

const MainImage = styled.img<{isShow: boolean}>`
	position: absolute;
	left: calc(50% - 520px);
	transform: translateX(-50%);
	top: -209px;
	max-width: 1550px;
	${({theme}) => theme.mediaQueries.md} {
		display: ${({isShow}) => isShow ? 'inline' : 'none'};
		max-width: 1000px;
		left: calc(50% - 175px);
		top: -150px;
	}
`

const SecondImage = styled.img<{isShow: boolean, step: number}>`
	position: absolute;
	left: calc(50% - 100px);
	${({step}) => step === 2 ? `transform: translateX(-50%);` : `transform: translate(-50%, -150px);`}
	top: -100%;
	max-width: 1535px;
	display: none;
	${({theme}) => theme.mediaQueries.md} {
		display: ${({isShow}) => isShow ? 'inline' : 'none'};
	}
`

const Shadow = styled.div`
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	display: none;
	height: 222px;
	z-index: 1;
	background: linear-gradient(180deg, #070708 0%, rgba(7, 7, 8, 0.00) 100%);
	${({theme}) => theme.mediaQueries.md} {
		display: flex;
	}
`

const ContainerStyled = styled(Container)`
	flex-wrap: wrap;
`

function CreateWalletPage() {
	const {step} = useWalletState()
	return (
		<Wrapper>
			<Shadow/>
			<MainImage isShow={step === 1} src={mainImg}/>
			<SecondImage isShow={step > 1} step={step} src={secondImg}/>
			<ContainerStyled>
				<View />
			</ContainerStyled>
		</Wrapper>
	)
}

export default CreateWalletPage
