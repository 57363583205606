import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import classes from "../../components/CreateWalletComponents/View/View.module.scss";
import {useWalletAction} from "../../store/wallet/hooks";
import {addZeroForward, generateArrayFrom} from "../../utils";
import {Button, EButtonVariants} from "../../UI/Button";

const InputWord = styled.input`
  background: transparent;
  width: 100px;
  max-width: 100%;
  border: none;
  outline: none;
`

const ButtonStyled = styled(Button)`
  padding: 25px;
  width: 100%;
  max-width: 342px;
  margin-top: 40px;
`

function RestoreView() {

  const {onRestoreWallet} = useWalletAction()


  const [checkedItems, setCheckedItems] = useState<{ [index: number]: string }>({});

  const onChange = (index: number, value: string) => setCheckedItems(prevState => ({...prevState, [index]: value}))


  const onPaste = (event) => {
    event.preventDefault()
    const pasted = event.clipboardData.getData("text/plain")
    const arr: string[] = pasted.split(' ')
    setCheckedItems(arr.reduce((acc, item, id) => ({...acc, [id]: item}), {}))
  }

  const onRestore = useCallback(() => {
    if (Object.values(checkedItems).filter((val) => val.trim() !== '').length === 12) onRestoreWallet(Object.values(checkedItems))
  }, [checkedItems, onRestoreWallet])

  const onKeyHandler = useCallback((event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      const currentId = event.currentTarget.id
      const id = parseInt(currentId.split('-')[1], 10)
      if (id < 11) {
        const nextElement = document.getElementById(`input-${id + 1}`)
        nextElement.focus()
      } else {
        onRestore()
      }
    } else if (event.key === "Delete" || event.keyCode === 46) {
      setCheckedItems({})
    }
  }, [onRestore])

  return (
    <div className={[classes.viewTab, classes.three, classes.active, ''].join(' ')}>
      <div className={[classes.viewTabRestoreContent, ''].join(' ')}>
        <h2 className={[classes.viewTabContentTitle, ''].join(' ')}>
          Wallet Restore
        </h2>
        <div className={[classes.viewTabContentRow, ''].join(' ')}>
          {generateArrayFrom(0, 12).map((index) => {
              return (
                <div
                  key={index}
                  className={[classes.viewTabContentRowWord, classes.restore, ''].join(' ')}
                >
                  <span>{addZeroForward((index + 1).toString())}</span>
                  <InputWord id={`input-${index}`} onKeyDown={event => onKeyHandler(event)}
                             value={checkedItems[index] || ''} onChange={event => onChange(index, event.target.value)}
                             onPaste={onPaste}/>
                </div>
              )
            }
          )}
        </div>
        <ButtonStyled disabled={Object.values(checkedItems).filter((val) => val.trim() !== '').length !== 12}
                      variant={EButtonVariants.Primary} onClick={() => onRestore()}>
          Restore
        </ButtonStyled>
      </div>
    </div>
  );
}

export default RestoreView;
