import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <path d="M12 2.00049C6.477 2.00049 2 6.47749 2 12.0005C2 17.0135 5.693 21.1535 10.505 21.8765V14.6505H8.031V12.0215H10.505V10.2725C10.505 7.37649 11.916 6.10549 14.323 6.10549C15.476 6.10549 16.085 6.19049 16.374 6.22949V8.52349H14.732C13.71 8.52349 13.353 9.49249 13.353 10.5845V12.0215H16.348L15.942 14.6505H13.354V21.8975C18.235 21.2365 22 17.0625 22 12.0005C22 6.47749 17.523 2.00049 12 2.00049Z" fill="#070708"/>
        </Svg>
    );

export default Icon;
