import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="21" height="23" viewBox="0 0 21 23" fill="none" {...props} >
            <g clipPath="url(#)">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.872 0.298828C15.2392 3.47806 17.0013 5.3735 20.0651 5.57514V9.15093C18.2896 9.32569 16.7343 8.74092 14.9254 7.63861V14.3264C14.9254 22.8223 5.72739 25.4772 2.02949 19.3876C-0.346783 15.4691 1.10835 8.59305 8.73111 8.31748V12.0882C8.15039 12.1823 7.52962 12.3302 6.96225 12.5251C5.26682 13.1031 4.30564 14.1853 4.57263 16.0942C5.0866 19.7506 11.7482 20.8327 11.1942 13.6879V0.30555H14.872V0.298828Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="">
                    <rect width="19.0836" height="22.4025" fill="white" transform="translate(0.981323 0.298828)"/>
                </clipPath>
            </defs>
        </Svg>
    );

export default Icon;
