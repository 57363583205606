import React from 'react';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {dark} from "./theme";
import { store } from './store';


interface Props {
  children: React.ReactNode
}

function Providers({children}: Props) {
  return (
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={dark}>
            {children}
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
  );
}

export default Providers;
