import React, {useCallback, useEffect, useMemo, useState} from 'react'
import dayjs from "dayjs";
import styled from "styled-components";
import _ from "lodash";
import {Address, fromNano, toNano} from "@ton/core";
import classes from "./OutputModal.module.scss"
import {Modal} from '../../../UI/Modal'
import {useAppAction, useAppState} from "../../../store/app/hooks";
import {EModal} from "../../../store/app/types";
import {Button, EButtonVariants} from "../../../UI/Button";
import {Dropdown} from "../../../UI/Dropdown";
import {useWalletAction, useWalletState} from "../../../store/wallet/hooks";
import useBlockNumber from "../../../hooks/useBlockNumber";
import {escapeRegExp, inputRegex} from "../../../utils";
import {EAlertVariants} from "../../../UI/Alert/types";
import SelectFundComponent from "../../WalletComponents/SelectFundComponent";
import {Input} from "../../../UI/Input";
import {useAssetsOptions} from "../../../hooks/useAssetsOptions";
import {IAsset} from "../../../config/assets";
import {calculateTransfer, createTransfer, getJettonWalletAddress, sendJettons} from "../../../utils/ton";
import {BASE_BSC_SCAN_URLS, ChainId} from "../../../config";

const ButtonStyled = styled(Button)`
  padding: 25px;
  width: 100%;
  border-color: #09090B;
  color: #09090B;
  margin-top: 44px;
`

const DropdownStyled = styled(Dropdown)`
  width: 100%;
`

const Label = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-left: 9px;
  color: ${({theme}) => theme.colors.border};
`

const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
  gap: 4px;
`
const ListWrapper = styled.div`
  overflow: auto;
  width: 100%;
  padding: 10px;
`

const List = styled.div`
  display: flex;
  gap: 10px;
  align-items: stretch;
  margin: 0 -4px;
`
const ButtonNext = styled(Button)`
  padding: 20px 60px;
  margin-top: 48px;
  max-width: unset;
  width: 100%;

  span {
    line-height: inherit;
    font-size: 18px;

    background: transparent;
    background: -webkit-linear-gradient(#4CA9FF, #4C89FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg path {
    stroke: #4CA9FF;
  }

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const RowLeft = styled.div`
  color: ${({theme}) => theme.colors.border};
  font-weight: 400;
`
const RowRight = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-weight: 600;
`

function OutputModal() {
  const {onModalClose, onAlertOpen} = useAppAction()
  const {modal} = useAppState()
  const {assets, walletData} = useWalletState()
  const {onAddTransaction, onRemoveTransaction, onFetchTransactions} = useWalletAction()
  const blockNumber = useBlockNumber()
  const [amount, setAmount] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedChainId, setSelectedChainId] = useState<number | null>(null);
  const [pending, setPending] = useState<boolean>(false)

  const [selectedAssetAddress, setSelectedAssetAddress] = useState<string>('');

  const onAmountHandler = useCallback((nextUserInput: string) => {
    if ((nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput)))) {
      nextUserInput = nextUserInput.replace(/,/g, '.')
      setAmount(nextUserInput !== '' ? nextUserInput : '')
    }
  }, [])

  const onClose = () => onModalClose(EModal.Output)

  const [withdrawAddress, setWithdrawAddress] = useState('');

  // const isWithdrawEthAddress = useMemo(() => isAddress(withdrawAddress), [withdrawAddress])

  const {opts, assetsWithBalance} = useAssetsOptions(selectedChainId)

  const selectedNetwork: IAsset | null = useMemo(() => assets !== null ? _.find(assets, {chainId: selectedChainId}) : null, [assets, selectedChainId])
  const selectedAsset: IAsset | null = useMemo(() => selectedAssetAddress !== null ? _.find(assetsWithBalance, {address: selectedAssetAddress}) : null, [selectedAssetAddress, assetsWithBalance])

  const onClearForm = () => {
    setAmount('')
    setIsValid(false)
    setSummary('')
    setSelectedAssetAddress('')
    setSelectedChainId(null)
  }

  const calculateTonFees = useCallback(async () => {
    if (selectedAsset) {
      const withdrawSum = parseFloat(amount)
      if (amount && selectedAsset.address) {
        try {
          setSummary(
            `${withdrawSum} ${selectedAsset.symbol} + min ${0.2} ${selectedNetwork?.symbol.toUpperCase()}`
          );
          setIsValid(true)
        } catch {
          setIsValid(false);
          setSummary('Insufficient balance');
        }
      } else if (!selectedAsset.address && withdrawSum) {
        const calculateFee = await calculateTransfer(walletData, Address.parse(withdrawAddress), toNano(amount).toString())
        const totalSum = calculateFee + toNano(amount)
        const isHaveTon = totalSum < toNano(selectedAsset.balance || 0)
        if (isHaveTon) {
          setIsValid(true);
          setSummary(`${fromNano(totalSum)} ${selectedNetwork?.symbol.toUpperCase()}`)
        } else {
          setIsValid(false)
          setSummary('Insufficient balance')
        }
      }
    }
  }, [selectedAsset, amount])

  useEffect(() => {
    if (selectedChainId !== null) {
      calculateTonFees()
    }
  }, [calculateTonFees, selectedChainId])


  const onWithdraw = useCallback(async () => {
    setPending(true)
    try {
      onAlertOpen({
        variant: EAlertVariants.Info,
        title: "Withdraw",
        content: 'Transaction is sent! Check Your wallet on viewer!',
        externalLink: `${BASE_BSC_SCAN_URLS[ChainId.TON]}/${walletData.address}`
      })
      let txHash = ''
      let succesTx = true
      const date = (new Date())
      if (selectedAsset.address !== '') {
        const jettonAddress = await getJettonWalletAddress(walletData.address.toString(), selectedAsset.address)
        const {hash, success} = await sendJettons(walletData, jettonAddress, withdrawAddress, amount)
        if (success) txHash = hash
        else succesTx = success
      } else {
        onAddTransaction({
          transactionType: 'withdraw',
          client: withdrawAddress.toString(),
          value: amount,
          date: dayjs(date).locale('en').format('D MMM YYYY'),
          timestamp: date.getTime(),
          symbol: 'TON',
          hash: '',
          chainId: 0,
        })
        const {hash, success} = await createTransfer(walletData, Address.parse(withdrawAddress), amount)
        if (success) txHash = hash
        else succesTx = success
      }
      if (!succesTx) {
        console.log('remove')
        onRemoveTransaction(date.getTime())
      } else {
        onFetchTransactions()
      }
      onAlertOpen({
        variant: succesTx ? EAlertVariants.Success : EAlertVariants.Error,
        title: "Withdraw",
        content: succesTx ? 'Withdrawal completed!' : 'Withdrawal failed!',
        trx: succesTx ? txHash : ''
      })
      onClearForm()
      onClose()
    } catch (error) {
      console.log(error);
      onAlertOpen({
        variant: EAlertVariants.Error,
        title: "Withdraw",
        content: 'Withdrawal failed'
      })
    }
    setPending(false)
  }, [selectedAsset, withdrawAddress, amount, walletData])

  if (!modal?.[EModal.Output]) return null

  return (
    <Modal
      desc={selectedAsset ? `TON or Jettons only` : ''}
      title="Withdraw" onClose={onClose}>
      <FormBlock>
        <Label>
          Choose network
        </Label>
        <ListWrapper>
          <List>
            {
              assets.map((fund, id) => <SelectFundComponent onSelect={val => {
                setSelectedChainId(val)
                setSelectedAssetAddress('')
              }}
                                                            isSelected={fund.chainId === selectedChainId} key={id}
                                                            fund={fund}/>)
            }
          </List>
        </ListWrapper>
      </FormBlock>
      {
        selectedChainId !== null && <FormBlock>
              <Label>
                  Select active
              </Label>
              <DropdownStyled options={opts} value={selectedAssetAddress} onSelect={setSelectedAssetAddress}
                              placeholder="Select asset"/>
          </FormBlock>
      }
      <FormBlock>
        <Label>
          Address
        </Label>
        <Input value={withdrawAddress} placeholder='Address' onChange={setWithdrawAddress}/>
      </FormBlock>
      <FormBlock>
        <Label>
          Amount
        </Label>
        <Input value={amount} placeholder='Amount' onChange={onAmountHandler}/>
      </FormBlock>

      <Row>
        <RowLeft>Balance</RowLeft>
        <RowRight>{selectedAsset?.balance} {selectedAsset?.symbol}</RowRight>
      </Row>
      {amount && <div className={[classes.depositContentRow, ""].join(" ")}>
          <p className={[classes.depositContentRowLeft, classes.summary, !isValid ? classes.error : ""].join(" ")}>
            {summary}
          </p>
      </div>}
      {isValid &&
          <ButtonNext variant={EButtonVariants.White} disabled={!isValid || !amount || pending} onClick={onWithdraw}>
              <span>Send</span>
            {/* <ArrowNextIcon/> */}
          </ButtonNext>
      }
    </Modal>
  )
}

export default OutputModal
