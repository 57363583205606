import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import TransactionComponent from '../../components/WalletComponents/TransactionComponent';
import {UpdateIcon} from "../../UI/Svg";
import {useWalletState} from "../../store/wallet/hooks";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`

const TitleBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

const UpdateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ListWrapper = styled.div`
  overflow: auto;
  width: 100%;
`

const List = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-direction: column;
`

const SeeAll = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.4);
`

function TransactionsView() {
  const {transactions, waitingTransactions, selectedWalletId} = useWalletState()
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  useEffect(() => {
    setIsShowAll(false)
  }, [selectedWalletId])

  const transactionsFilters = useMemo(() => {
    if (selectedWalletId !== null) {
      return transactions.filter((tx) => tx.chainId === selectedWalletId)
    }
    return transactions
  }, [transactions, selectedWalletId])

  const waitingTransactionsFilters = useMemo(() => {
    if (selectedWalletId !== null) {
      return waitingTransactions.filter((tx) => tx.chainId === selectedWalletId)
    }
    return waitingTransactions
  }, [transactions, selectedWalletId])

  return (
    <Wrapper>
      {waitingTransactionsFilters.length > 0 && <><TitleBlock>
          Your waiting transactions
      </TitleBlock>
          <ListWrapper>
              <List>
                {waitingTransactionsFilters.map((transaction, id) =>
                  <TransactionComponent key={`transaction-${id}`} {...transaction}/>)}
              </List>
          </ListWrapper>
      </>
      }
      <TitleBlock>
        Your transactions
      </TitleBlock>
      <ListWrapper>
        <List>
          {(isShowAll ? transactionsFilters : transactionsFilters.slice(0, 4)).map((transaction, id) =>
            <TransactionComponent key={`transaction-${id}`} {...transaction}/>)}
        </List>
      </ListWrapper>
      {(!isShowAll && transactionsFilters.length > 4) && <SeeAll onClick={() => setIsShowAll(true)}>
          <UpdateButton>
              <UpdateIcon/>
          </UpdateButton>
          See all transactions
      </SeeAll>}
    </Wrapper>
  );
}

export default TransactionsView;
