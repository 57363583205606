import { DefaultTheme } from "styled-components";
import {breakpoints, mediaQueries, shadows} from "./base";
import { darkColors } from "./colors";
import {dark as button} from "../UI/Button/theme";
import {dark as alert} from "../UI/Alert/theme";

const darkTheme: DefaultTheme = {
    isDark: false,
    colors: darkColors,
    breakpoints,
    mediaQueries,
    shadows,
    button,
    alert,
};

export default darkTheme;
