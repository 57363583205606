import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {PriceApi} from "../../api/price";
import {assets} from "../../config/assets";


const fetchPrices = createAsyncThunk<{ [coin: string]: number },
  void,
  {
    state: RootState
  }>(
  'app/fetchPrices',
  async (_) => {
    try {
      const symbols = assets.map(({symbol}) => symbol)
      const promises = symbols.map((symbol) => PriceApi.getPrice({symbol, quote_symbol: 'USD'}),)
      const res = await Promise.all(promises)
      return res.reduce((acc, {data}, index) => ({...acc, [symbols[index]]: parseFloat(data.data.price)}), {})
    } catch (e) {
      console.log(e);
    }
    return {}
  }
)

export default fetchPrices
