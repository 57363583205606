import React from 'react';
import styled from "styled-components";
import FundComponent from "./FundComponent";
import {IAsset} from "../../config/assets";

const StyledFund = styled(FundComponent)<{isSelected: boolean}>`
  ${({isSelected}) => isSelected ? `outline: 3px solid #4CBFFF;` : '' }
  
`

function SelectFundComponent({isSelected, onSelect, fund}:{isSelected: boolean, onSelect: (val: number) => void, fund: IAsset}) {
  return (
    <StyledFund onClick={() => {
      onSelect(fund.chainId)
    }} fund={fund} isSelected={isSelected}/>
  );
}

export default SelectFundComponent;
