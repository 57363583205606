import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import jettons from "../config/token-lists/jettonsDev.json";
import {useWalletState} from "../store/wallet/hooks";
import AssetRowComponent from "../components/WalletComponents/AssetRowComponent";
import {IAsset} from "../config/assets";
import {getBalance} from "../store/wallet/fetchAssetsBalance";
import {ChainId} from "../config";


const AssetRowStyled = styled(AssetRowComponent)`
  padding: 0 !important;

  &:hover {
    background: unset;
  }
`


export function useAssetsOptions(selectedChainId: number | null) {
  const {assets, walletData} = useWalletState()
  const [assetsWithBalance, setAssetsWithBalance] = useState([]);

  useEffect(() => {
    if (selectedChainId !== null) {
      if (walletData) {
        let userAddress = ''
        let arr = []
        if (selectedChainId === ChainId.TON) {
          arr = [...assets.filter(({chainId}) => chainId === selectedChainId),
            ...jettons.map(jetton => ({...jetton, logoURI: jetton.image, chainId: ChainId.TON}))
          ]
          userAddress = walletData.address
        }
        const data: IAsset[] = []
        try {
          for (const asset of arr) {
            // @ts-ignore
            data.push(getBalance(asset, userAddress))
          }
          Promise.all(data).then(setAssetsWithBalance)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [assets, selectedChainId, walletData])

  const opts = useMemo(() => {
    if (selectedChainId !== null) {
      return assetsWithBalance.map((asset) => ({
        value: asset.address,
        item: <AssetRowStyled asset={asset}/>
      }))
    }
    return []
  }, [assetsWithBalance])

  return useMemo(() => ({
    assetsWithBalance,
    opts,
  }), [assetsWithBalance, opts])
}
