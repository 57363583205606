import React, {useMemo} from 'react'
import styled from "styled-components";
import {CopyToClipboard} from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import {useAppAction, useAppState} from "../../../store/app/hooks";
import {EModal} from "../../../store/app/types";
import {Modal} from '../../../UI/Modal'
import {Button, EButtonVariants} from "../../../UI/Button";
import {useWalletState} from "../../../store/wallet/hooks";
import {CopyBlockIcon, CopyIcon} from "../../../UI/Svg";
import {shortAddress} from "../../../utils";
import {EAlertVariants} from "../../../UI/Alert/types";
import FundComponent from "../../WalletComponents/FundComponent";
import {ChainId} from "../../../config";


const ButtonStyled = styled(Button)`
  padding: 22px;
  width: 100%;
  border-color: ${({theme}) => theme.colors.borderLight};
  background: ${({theme}) => theme.colors.white};
  margin-top: 28px;
  background: ${({theme}) => theme.colors.borderLight};
`

const ButtonCopy = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

const QRCodeWrapper = styled.div`
  width: 176px;
  margin: 29px auto 0;
  border-radius: 19px;
  height: 176px;
  background: ${({theme}) => theme.colors.white};
  padding: 11px;
`

const ROIBlock = styled.div`
  width: 100%;
  border-radius: 19px;
  background: rgba(63, 63, 66, 0.50);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 20px 20px 27px;
`

const Label = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const AddressRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: ${({theme}) => theme.colors.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;

  svg path {
    fill: ${({theme}) => theme.colors.white};
  }
`

const RowAssets = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
`

const getDescText = (chainId: number) => {
  switch (chainId) {
    case ChainId.TON:
      return 'TON or Jettons only'
    case ChainId.MAINNET:
      return 'ETH or ERC20 tokens only'
    case ChainId.TESTNET:
      return 'BNB or BEP20 tokens only'
    default:
      return ''
  }
}

function DepositModal() {
  const {onModalClose, onAlertOpen} = useAppAction()
  const {modal} = useAppState()
  const {walletData, selectedWalletId, assets} = useWalletState()

  const selectedAsset = useMemo(() => assets.find(({chainId}) => chainId === selectedWalletId), [selectedWalletId])

  const onClose = () => onModalClose(EModal.Deposit)

  const walletAddress = useMemo(() => {
    if (selectedWalletId !== null) {
      if (selectedWalletId === ChainId.TON && walletData) {
        return walletData.address
      }
    }
    return null
  }, [selectedWalletId, walletData])

  if (!modal?.[EModal.Deposit]) return null

  return (
    <Modal
      desc={selectedAsset ? getDescText(selectedAsset.chainId) : ''}
      title="Deposit" onClose={onClose}>
      {selectedAsset && <RowAssets>
        <FundComponent fund={selectedAsset}/>
      </RowAssets>}
      <ROIBlock>
        <Label>
          Click to copy
        </Label>
        <AddressRow>
          {walletAddress ? shortAddress(walletAddress, 14, -14) : ''}
          <CopyToClipboard onCopy={() => {
            onAlertOpen({variant: EAlertVariants.Info, title: 'Copy', content: 'Copied'})
          }} text={walletAddress || ''}>
            <ButtonCopy>
              <CopyIcon/>
            </ButtonCopy>
          </CopyToClipboard>
        </AddressRow>
      </ROIBlock>
      {walletAddress && <QRCodeWrapper>
        <QRCode
          size={189}
          style={{height: "auto", maxWidth: "100%", width: "100%"}}
          value={walletAddress}
          viewBox="0 0 189 189"
        />
      </QRCodeWrapper>}

      <ButtonStyled variant={EButtonVariants.Secondary} onClick={onClose}>
        Close
      </ButtonStyled>
    </Modal>
  )
}

export default DepositModal
