import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...props} >
          <path d="M30.9144 33.6409V28.516H25.7894V25.9535H30.9144V20.8285H33.4769V25.9535H38.6019V28.516H33.4769V33.6409H30.9144ZM5.48655 35.0208C4.62364 35.0208 3.89323 34.7218 3.29531 34.1239C2.6974 33.526 2.39844 32.7956 2.39844 31.9326V9.06734C2.39844 8.20441 2.6974 7.47398 3.29531 6.87606C3.89323 6.27815 4.62364 5.97919 5.48655 5.97919H28.3519C29.2148 5.97919 29.9452 6.27815 30.5431 6.87606C31.141 7.47398 31.44 8.20441 31.44 9.06734V17.0833H28.8776V13.1409H4.9609V31.9326C4.9609 32.0859 5.01017 32.2119 5.10871 32.3105C5.20728 32.409 5.33323 32.4583 5.48655 32.4583H27.1692V35.0208H5.48655ZM4.9609 10.5785H28.8776V9.06734C28.8776 8.91402 28.8283 8.78807 28.7297 8.6895C28.6311 8.59093 28.5052 8.54165 28.3519 8.54165H5.48655C5.33323 8.54165 5.20728 8.59093 5.10871 8.6895C5.01017 8.78807 4.9609 8.91402 4.9609 9.06734V10.5785Z" fill="white"/>
        </Svg>
    );

export default Icon;
