import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...props} >
          <path d="M10.2496 33.3125C8.60046 33.3125 7.19164 32.7283 6.02317 31.5598C4.85473 30.3913 4.27051 28.9825 4.27051 27.3334V13.6667C4.27051 12.0175 4.85473 10.6087 6.02317 9.44023C7.19164 8.27178 8.60046 7.68756 10.2496 7.68756H30.7496C32.3988 7.68756 33.8076 8.27178 34.9761 9.44023C36.1445 10.6087 36.7288 12.0175 36.7288 13.6667V27.3334C36.7288 28.9825 36.1445 30.3913 34.9761 31.5598C33.8076 32.7283 32.3988 33.3125 30.7496 33.3125H10.2496ZM10.2496 14.0938H30.7496C31.3979 14.0938 32.0095 14.1869 32.5845 14.373C33.1594 14.5592 33.6867 14.8418 34.1663 15.2207V13.6667C34.1663 12.7271 33.8318 11.9228 33.1627 11.2537C32.4936 10.5846 31.6892 10.25 30.7496 10.25H10.2496C9.31005 10.25 8.50571 10.5846 7.83661 11.2537C7.16751 11.9228 6.83297 12.7271 6.83297 13.6667V15.2207C7.31261 14.8418 7.83989 14.5592 8.4148 14.373C8.98974 14.1869 9.60135 14.0938 10.2496 14.0938ZM6.99064 19.2023L26.1766 23.8641C26.4109 23.9211 26.648 23.9238 26.8878 23.8724C27.1276 23.8209 27.345 23.7152 27.5399 23.5553L33.6899 18.3876C33.4096 17.8729 33.0083 17.4557 32.4859 17.1359C31.9635 16.8161 31.3848 16.6562 30.7496 16.6562H10.2496C9.44364 16.6562 8.74388 16.8922 8.15035 17.3642C7.55681 17.8362 7.17025 18.4489 6.99064 19.2023Z" fill="white"/>
        </Svg>
    );

export default Icon;
