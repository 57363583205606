import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="33" height="33" viewBox="0 0 33 33" fill="none" {...props} >
          <path d="M16.5 30.25C14.5979 30.25 12.8104 29.8891 11.1375 29.1672C9.46458 28.4453 8.00938 27.4656 6.77187 26.2281C5.53437 24.9906 4.55469 23.5354 3.83281 21.8625C3.11094 20.1896 2.75 18.4021 2.75 16.5C2.75 14.5979 3.11094 12.8104 3.83281 11.1375C4.55469 9.46458 5.53437 8.00938 6.77187 6.77187C8.00938 5.53437 9.46458 4.55469 11.1375 3.83281C12.8104 3.11094 14.5979 2.75 16.5 2.75C17.9896 2.75 19.399 2.96771 20.7281 3.40312C22.0573 3.83854 23.2833 4.44583 24.4062 5.225L22.4125 7.25313C21.5417 6.70313 20.6135 6.27344 19.6281 5.96406C18.6427 5.65469 17.6 5.5 16.5 5.5C13.4521 5.5 10.8568 6.57135 8.71406 8.71406C6.57135 10.8568 5.5 13.4521 5.5 16.5C5.5 19.5479 6.57135 22.1432 8.71406 24.2859C10.8568 26.4286 13.4521 27.5 16.5 27.5C19.5479 27.5 22.1432 26.4286 24.2859 24.2859C26.4286 22.1432 27.5 19.5479 27.5 16.5C27.5 16.0875 27.4771 15.675 27.4313 15.2625C27.3854 14.85 27.3167 14.449 27.225 14.0594L29.4594 11.825C29.7115 12.5583 29.9062 13.3146 30.0438 14.0938C30.1812 14.8729 30.25 15.675 30.25 16.5C30.25 18.4021 29.8891 20.1896 29.1672 21.8625C28.4453 23.5354 27.4656 24.9906 26.2281 26.2281C24.9906 27.4656 23.5354 28.4453 21.8625 29.1672C20.1896 29.8891 18.4021 30.25 16.5 30.25ZM14.575 22.825L8.73125 16.9813L10.6562 15.0562L14.575 18.975L28.325 5.19062L30.25 7.11562L14.575 22.825Z" fill="#FF4418"/>
        </Svg>
    );

export default Icon;
