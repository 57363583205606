import React, {useEffect} from 'react';
import styled from "styled-components";
import {EAlertVariants, IAlert} from "./types";
import {CloseIcon, ErrorIcon, InfoIcon, SuccessIcon} from "../Svg";
import {useAppAction} from "../../store/app/hooks";
import {BASE_BSC_SCAN_URLS, ChainId, defaultChainId, mainUrl} from "../../config";
import {shortAddress} from "../../utils";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex: 1;
  padding-right: 12px;
  ${({theme}) => theme.mediaQueries.md} {
    padding-right: 20px;
  }
`

const Title = styled.h3`
  font-size: 16px;
  line-height: 1;
  color: ${({theme}) => theme.colors.white};
`

const Content = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 1;
  color: ${({theme}) => theme.colors.white};
  opacity: .4;
  white-space: pre-wrap;
  word-break: break-word;
`


const AlertWrapper = styled.div<{ variant: EAlertVariants }>`

  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  position: relative;
    // background: ${({theme, variant}) => theme.alert[variant].background};
  background: ${({theme}) => theme.colors.white};
  border-radius: 10px;
  backdrop-filter: blur(12px);
  padding: 14px 24px;
  //min-width: 200px;
  //max-width: 320px;
  width: 280px;
  z-index: 10000;

  ${Title} {
      // color: ${({theme, variant}) => theme.alert[variant].text};
    color: ${({theme}) => theme.colors.black};
  }

  ${Content} {
      // color: ${({theme, variant}) => theme.alert[variant].text};
    color: ${({theme}) => theme.colors.black};
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 350px;
  }
`


const TransactionLink = styled.a`
  text-decoration: underline;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  color: ${({theme}) => theme.colors.black};
`
const AlertLink = styled.a`
  text-decoration: underline;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  color: ${({theme}) => theme.colors.black};
  word-break: break-word;
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 4px;
  position: absolute;
  right: 8px;
  top: 27px;
  background: none;
  cursor: pointer;

  svg path {
    fill: ${({theme}) => theme.colors.black};
  }
  ${({theme}) => theme.mediaQueries.md} {
    right: 16px;
  }
`

const IconWrapper = styled.div<{ variant: EAlertVariants }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 15px;
  background: ${({theme, variant}) => theme.alert[variant].background};

  & > svg {
    min-width: 33px;
    height: auto;

    path {
      fill: ${({theme, variant}) => theme.alert[variant].icon};
    }
  }
`

function Alert({alert}: { alert: IAlert }) {
  const {onAlertClose} = useAppAction()

  useEffect(() => {
    let timeout: any
    if (alert) {
      timeout = setTimeout(onAlertClose, 1000 * 15)
    }
    if (timeout) {
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [alert])

  if (!alert) return null
  return (
    <AlertWrapper variant={alert.variant}>
      <IconWrapper variant={alert.variant}>
        {alert.variant === EAlertVariants.Success && <SuccessIcon/>}
        {alert.variant === EAlertVariants.Error && <ErrorIcon/>}
        {alert.variant === EAlertVariants.Info && <InfoIcon/>}
      </IconWrapper>
      <Main>
        <Title>{alert.title}</Title>
        <Content>{alert.content}</Content>
        {alert.trx && <TransactionLink target='_blank' rel='noreferrer'
                                       href={`${BASE_BSC_SCAN_URLS[ChainId.TON]}/transaction/${alert.trx}`}>{shortAddress(alert.trx)}</TransactionLink>}
        {alert.externalLink && <TransactionLink target='_blank' rel='noreferrer'
                                       href={alert.externalLink}>{shortAddress(alert.externalLink)}</TransactionLink>}
        {alert.link &&
            <AlertLink onClick={() => onAlertClose()} href={alert.link}>{mainUrl.slice(0, -1) + alert.link}</AlertLink>}
      </Main>
      <CloseButton onClick={onAlertClose}>
        <CloseIcon/>
      </CloseButton>
    </AlertWrapper>
  );
}

export default React.memo(Alert);
