import {createAsyncThunk} from "@reduxjs/toolkit";
import {mnemonicNew} from "@ton/crypto";
import {RootState} from "../index";
import {createWallet} from "../../utils/ton";
import {TTonWallet} from "./types";


const fetchCreateWallet = createAsyncThunk<{ wallet: TTonWallet, mnemonic: string },
  void,
  {
    state: RootState
  }>(
  'wallet/fetchCreateWallet',
  async (_) => {
    const mnemonic = (await mnemonicNew(12)).join(' ')
    const wallet = await createWallet(mnemonic)
    return {
      wallet,
      mnemonic
    }
  }
)

export default fetchCreateWallet
