import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...props} >
          <path d="M19.9457 21.1506L13.6457 14.8506C13.1457 15.2506 12.5707 15.5673 11.9207 15.8006C11.2707 16.034 10.579 16.1506 9.8457 16.1506C8.02904 16.1506 6.49154 15.5215 5.2332 14.2631C3.97487 13.0048 3.3457 11.4673 3.3457 9.65063C3.3457 7.83397 3.97487 6.29647 5.2332 5.03813C6.49154 3.7798 8.02904 3.15063 9.8457 3.15063C11.6624 3.15063 13.1999 3.7798 14.4582 5.03813C15.7165 6.29647 16.3457 7.83397 16.3457 9.65063C16.3457 10.384 16.229 11.0756 15.9957 11.7256C15.7624 12.3756 15.4457 12.9506 15.0457 13.4506L21.3457 19.7506L19.9457 21.1506ZM9.8457 14.1506C11.0957 14.1506 12.1582 13.7131 13.0332 12.8381C13.9082 11.9631 14.3457 10.9006 14.3457 9.65063C14.3457 8.40063 13.9082 7.33813 13.0332 6.46313C12.1582 5.58813 11.0957 5.15063 9.8457 5.15063C8.5957 5.15063 7.5332 5.58813 6.6582 6.46313C5.7832 7.33813 5.3457 8.40063 5.3457 9.65063C5.3457 10.9006 5.7832 11.9631 6.6582 12.8381C7.5332 13.7131 8.5957 14.1506 9.8457 14.1506Z" fill="white"/>
        </Svg>
    );

export default Icon;
