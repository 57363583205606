import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="20" height="15" viewBox="0 0 20 15" fill="none" {...props} >
            <path opacity="0.3" d="M1 13.5C0.723858 13.5 0.5 13.7239 0.5 14C0.5 14.2761 0.723858 14.5 1 14.5V13.5ZM16.3536 0.646446C16.1583 0.451184 15.8417 0.451184 15.6464 0.646446L12.4645 3.82843C12.2692 4.02369 12.2692 4.34027 12.4645 4.53553C12.6597 4.7308 12.9763 4.7308 13.1716 4.53553L16 1.70711L18.8284 4.53553C19.0237 4.7308 19.3403 4.7308 19.5355 4.53553C19.7308 4.34027 19.7308 4.02369 19.5355 3.82843L16.3536 0.646446ZM1 14.5C5.07513 14.5 8.9437 13.4822 11.8057 11.2706C14.6852 9.04556 16.5 5.6458 16.5 1L15.5 0.999999C15.5 5.3542 13.8148 8.45444 11.1943 10.4794C8.5563 12.5178 4.92487 13.5 1 13.5V14.5Z" fill="white"/>
        </Svg>
    );

export default Icon;
