import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} >
          <path d="M12.1333 12.3333L5.4 8.56666L16 2.66666L26.6 8.56666L19.8667 12.3333C19.3556 11.8 18.7667 11.3889 18.1 11.1C17.4333 10.8111 16.7333 10.6667 16 10.6667C15.2667 10.6667 14.5667 10.8111 13.9 11.1C13.2333 11.3889 12.6444 11.8 12.1333 12.3333ZM14.6667 28.6L4 22.6667V10.8333L10.8333 14.6667C10.7667 14.8889 10.7222 15.1055 10.7 15.3167C10.6778 15.5278 10.6667 15.7555 10.6667 16C10.6667 17.2222 11.0333 18.3111 11.7667 19.2667C12.5 20.2222 13.4667 20.8555 14.6667 21.1667V28.6ZM16 18.6667C15.2667 18.6667 14.6389 18.4055 14.1167 17.8833C13.5944 17.3611 13.3333 16.7333 13.3333 16C13.3333 15.2667 13.5944 14.6389 14.1167 14.1167C14.6389 13.5944 15.2667 13.3333 16 13.3333C16.7333 13.3333 17.3611 13.5944 17.8833 14.1167C18.4056 14.6389 18.6667 15.2667 18.6667 16C18.6667 16.7333 18.4056 17.3611 17.8833 17.8833C17.3611 18.4055 16.7333 18.6667 16 18.6667ZM17.3333 28.6V21.1667C18.5333 20.8555 19.5 20.2222 20.2333 19.2667C20.9667 18.3111 21.3333 17.2222 21.3333 16C21.3333 15.7555 21.3222 15.5278 21.3 15.3167C21.2778 15.1055 21.2333 14.8889 21.1667 14.6667L28 10.8333V22.6667L17.3333 28.6Z" fill="#4CBFFF"/>
        </Svg>
    );

export default Icon;
