import React, { useState } from 'react'
import styled from 'styled-components'
import Container from '../components/Templates/Container'
import bg from '../assets/images/BGCreate.png'
import classes from "../components/CreateWalletComponents/View/View.module.scss";
import RestoreView from "../views/WalletView/RestoreView";
import mainImg from "../assets/images/MainImage.webp";
import secondImg from "../assets/images/SecondImage.webp";


const Wrapper = styled.div`
	padding-top: 100px;
	display: block;
	text-align: left;
	background: url("${bg}") no-repeat -26px 50px;
	background-size: 1500px auto;

	${({theme}) => theme.mediaQueries.md} {
		padding-top: 0;
		background-size: 856px auto;
		background-position: 20px 70px;
	}
`

const MainImage = styled.img`
	position: absolute;
	left: calc(50% - 550px);
	transform: translate(-50%, -50%);
	top: 50%;
	max-width: 1000px;
	display: block;
	${({theme}) => theme.mediaQueries.md} {
		display: none;
		max-width: 1000px;
		left: calc(50% - 175px);
		top: -150px;
	}
`

const SecondImage = styled.img`
	position: absolute;
	left: calc(50% - 100px);
	transform: translate(-50%, -250px);
	top: -100%;
	max-width: 1535px;
	display: none;
	${({theme}) => theme.mediaQueries.md} {
		display: inline;
	}
`

const Shadow = styled.div`
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	display: none;
	height: 222px;
	z-index: 1;
	background: linear-gradient(180deg, #070708 0%, rgba(7, 7, 8, 0.00) 100%);
	${({theme}) => theme.mediaQueries.md} {
		display: flex;
	}
`


const ContainerStyled = styled(Container)`
	flex-wrap: wrap;
`

function RestoreWalletPage() {
	return (
		<Wrapper>
			<ContainerStyled>

				<Shadow/>
				<MainImage src={mainImg}/>
				<SecondImage src={secondImg}/>
				<div className={[classes.view, ''].join(' ')}>
					<RestoreView/>
				</div>
			</ContainerStyled>
		</Wrapper>
	)
}

export default RestoreWalletPage
