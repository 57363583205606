import {ButtonTheme, EButtonVariants} from "./types";
import {darkColors} from "../../theme/colors";


export const dark: ButtonTheme = {
    [EButtonVariants.Primary]: {
        color: darkColors.white,
        background: 'linear-gradient(263deg, #4CA9FF 16.35%, #4C89FF 103.28%)',
        svgColor: darkColors.white
    },
    [EButtonVariants.Secondary]: {
        color: darkColors.white,
        background: 'rgba(37, 37, 40, 0.50)',
        svgColor: darkColors.primary
    },
    [EButtonVariants.White]: {
        color: darkColors.black,
        background: darkColors.white,
        svgColor: darkColors.black
    },
};
