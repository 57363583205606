import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

// dev
export const serverUrl = 'https://price-api.pironmind.com'
const apiUrl = `${serverUrl}/`
const mediaUrl = `${serverUrl}/`

const axiosInstance: AxiosInstance = axios.create({
    baseURL: apiUrl,
});

export const handle = (promise: Promise<AxiosResponse<any>>) => promise
        .then(data => ([data.data, undefined]))
        .catch(error => Promise.resolve([undefined, error?.response?.data || error?.response || error]))

export {axiosInstance, apiUrl, mediaUrl}
