import React from 'react';
import styled from "styled-components";
import Alert from './Alert';
import {useAppState} from "../../store/app/hooks";
import {IAlert} from "./types";

const AlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  align-content: flex-end;
  gap: 10px;
  position: fixed;
  top: 100px;
  right: 40px;
  z-index: 1100000;
`

function Alerts() {

  const {alert} = useAppState()
  return (
      <AlertsWrapper>
        {alert?.map((item: IAlert, id: number) => <Alert alert={item} key={`Alert-${id}`}/>)}
      </AlertsWrapper>
  );
}

export default Alerts;
