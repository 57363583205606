// import {getAddress} from 'ethers/lib/utils';
// import {Contract} from "ethers";

import {BigNumberish, formatUnits, parseUnits} from "ethers";

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export function sleep(ms: number) {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    });
}

export async function scrollToElement(event: any, href: string, isRedirect = false) {
    if (isRedirect) {
        await sleep(300)
    }
    const id = href.split('#')[1]
    if (id) {
        const element = document.getElementById(id);
        if (element) {
            const top = element.offsetTop;
            window.scrollTo({
                top,
                behavior: 'smooth',
            })
        }
    }
}

export const inputRegex = /^\d*(?:\\[.])?\d*$/; // match escaped "." characters via in a non-capturing group

export const inputRegexInt = /^\d*$/; // match escaped "." characters via in a non-capturing group

export const shortBalance = (balance: string, needLength = 6) => {
    const balanceArr = balance.split('.');
    if (balanceArr.length > 1) {
        const integerPart = balanceArr[0];
        const floatPart = balanceArr[1].length > needLength ? balanceArr[1].slice(0, needLength) : balanceArr[1];
        return `${integerPart}.${floatPart}`;
    }
    return balance;
};


export const shortAddress = (address: string, left = 6, right = -6) => {
    if (address.length <= 12) return address;
    return `${address.slice(0, left)}...${address.slice(right)}`;
};

export const addZeroForward = (string: string, needLength: number = 2) => `${'0'.repeat(needLength - string.length)}${string}`;


// // returns the checksummed address if the address is valid, otherwise returns false
// export function isAddress(value: any): string | false {
//     try {
//         return getAddress(value);
//     } catch {
//         return false;
//     }
// }

export function bufferToHex(buffer: Uint8Array) {
    return Array
        .from(buffer)
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
}


export function renderDate(timestamp: string, withTime?: boolean, withSeconds?: boolean): string {
    const date = new Date(parseInt(timestamp, 10) * 1000)
    if (withTime) {
        return (`${addZeroForward(date.getDate().toString())}.${addZeroForward((date.getMonth() + 1).toString())}.${date.getFullYear()}, ${addZeroForward(date.getHours().toString())}:${addZeroForward(date.getMinutes().toString())}${withSeconds ? `:${addZeroForward(date.getSeconds().toString())}` : ''}`)
    }
    return `${addZeroForward(date.getDate().toString())}.${addZeroForward((date.getMonth() + 1).toString())}.${date.getFullYear()}`
}


export function generateArrayFrom(startIndex: number, length: number) {
    return Array.from({length}, (_, i) => i + startIndex)
}


export const getMultiplier = (total: number, amount: number) => {
    if (total === 0 || amount === 0) {
        return 0
    }

    return (total / amount).toFixed(4)
}

/**
 *  Converts %%value%% into a //decimal string// using 9 decimal places.
 */
export function formatWed(wei: BigNumberish): string {
    return formatUnits(wei, 9);
}

/**
 *  Converts the //decimal string// %%ether%% to a BigInt, using 9
 *  decimal places.
 */
export function parseWed(ether: string): bigint {
    return parseUnits(ether, 9);
}

export default function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}
