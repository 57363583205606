import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="33" height="33" viewBox="0 0 33 33" fill="none" {...props} >
          <path d="M15.125 15.0975C15.125 14.7328 15.2699 14.3831 15.5277 14.1252C15.7856 13.8674 16.1353 13.7225 16.5 13.7225C16.8647 13.7225 17.2144 13.8674 17.4723 14.1252C17.7301 14.3831 17.875 14.7328 17.875 15.0975V23.3475C17.875 23.7122 17.7301 24.0619 17.4723 24.3198C17.2144 24.5776 16.8647 24.7225 16.5 24.7225C16.1353 24.7225 15.7856 24.5776 15.5277 24.3198C15.2699 24.0619 15.125 23.7122 15.125 23.3475V15.0975ZM16.5 8.32013C16.1353 8.32013 15.7856 8.46499 15.5277 8.72286C15.2699 8.98072 15.125 9.33046 15.125 9.69513C15.125 10.0598 15.2699 10.4095 15.5277 10.6674C15.7856 10.9253 16.1353 11.0701 16.5 11.0701C16.8647 11.0701 17.2144 10.9253 17.4723 10.6674C17.7301 10.4095 17.875 10.0598 17.875 9.69513C17.875 9.33046 17.7301 8.98072 17.4723 8.72286C17.2144 8.46499 16.8647 8.32013 16.5 8.32013Z" fill="#FF4418"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.5 2.75C8.90588 2.75 2.75 8.90588 2.75 16.5C2.75 24.0941 8.90588 30.25 16.5 30.25C24.0941 30.25 30.25 24.0941 30.25 16.5C30.25 8.90588 24.0941 2.75 16.5 2.75ZM5.5 16.5C5.5 19.4174 6.65893 22.2153 8.72183 24.2782C10.7847 26.3411 13.5826 27.5 16.5 27.5C19.4174 27.5 22.2153 26.3411 24.2782 24.2782C26.3411 22.2153 27.5 19.4174 27.5 16.5C27.5 13.5826 26.3411 10.7847 24.2782 8.72183C22.2153 6.65893 19.4174 5.5 16.5 5.5C13.5826 5.5 10.7847 6.65893 8.72183 8.72183C6.65893 10.7847 5.5 13.5826 5.5 16.5Z" fill="#FF4418"/>
        </Svg>
    );

export default Icon;
