import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Container from '../components/Templates/Container'
import OutputModal from '../components/Modals/OutputModal/OutputModal'
import DepositModal from '../components/Modals/DepositModal/DepositModal'
import {useAssetChanges, useWalletAction, useWalletState} from "../store/wallet/hooks";
import walletItemImg from "../assets/images/WalletItem.webp";
import secondImg from "../assets/images/SecondImage.webp";
import WalletMainView from "../views/WalletView/WalletMainView";
import FundsView from "../views/WalletView/FundsView";
import TransactionsView from "../views/WalletView/TransactionsView";

const Wrapper = styled.div`
	padding-top: 100px;
	display: block;
	text-align: left;
	${({ theme }) => theme.mediaQueries.md} {
		padding-top: 78px;
	}
`
const ContainerStyled = styled(Container)`
	flex-wrap: wrap;
	position: relative;
	z-index: 20;
	max-width: 723px;
	justify-content: flex-start;
	align-items: center;
	gap: 50px;
	flex-direction: column;
`

const SecondImage = styled.img`
	position: absolute;
	left: calc(50% - 100px);
	transform: translate(-50%, -250px);
	top: -100%;
	max-width: 1535px;
	display: none;
	${({theme}) => theme.mediaQueries.md} {
		display: inline;
	}
`

const Shadow = styled.div`
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	position: absolute;
	display: none;
	height: 222px;
	z-index: 1;
	background: linear-gradient(180deg, #070708 0%, rgba(7, 7, 8, 0.00) 100%);
	${({theme}) => theme.mediaQueries.md} {
		display: flex;
	}
`


const LeftImage = styled.img`
	position: absolute;
	left: -67px;
	transform: translateX(-50%);
	top: -378px;
	max-width: 1441px;
	display: block;
	${({theme}) => theme.mediaQueries.md} {
		display: none;
	}
`

const RightImage = styled(LeftImage)`
	left: unset;
	right: 0;
	transform: translateX(50%);
`


function WalletPage() {

	const {onFetchAssetsBalance, onFetchTransactions} = useWalletAction()
	const {assets, walletData} = useWalletState()

	useEffect(() => {
		onFetchAssetsBalance()
	}, [onFetchAssetsBalance, walletData])
	useEffect(() => {
		onFetchTransactions()
	}, [onFetchTransactions, assets])

	return (
		<>
			<OutputModal/>
			<DepositModal/>
			<Wrapper>
				{/* <div className='noise'>1</div> */}
				<Shadow/>
				<LeftImage src={walletItemImg}/>
				<RightImage src={walletItemImg}/>
				<SecondImage src={secondImg}/>
				<ContainerStyled>
					<WalletMainView/>
					<FundsView/>
					<TransactionsView/>
				</ContainerStyled>
			</Wrapper>
		</>
	)
}

export default WalletPage
