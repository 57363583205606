import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...props} >
          <path d="M9.375 18.75C8.80208 18.75 8.31163 18.546 7.90365 18.1381C7.49566 17.7301 7.29167 17.2396 7.29167 16.6667V4.16671C7.29167 3.59379 7.49566 3.10334 7.90365 2.69535C8.31163 2.28737 8.80208 2.08337 9.375 2.08337H18.75C19.3229 2.08337 19.8134 2.28737 20.2214 2.69535C20.6293 3.10334 20.8333 3.59379 20.8333 4.16671V16.6667C20.8333 17.2396 20.6293 17.7301 20.2214 18.1381C19.8134 18.546 19.3229 18.75 18.75 18.75H9.375ZM5.20833 22.9167C4.63542 22.9167 4.14497 22.7127 3.73698 22.3047C3.32899 21.8967 3.125 21.4063 3.125 20.8334V6.25004H5.20833V20.8334H16.6667V22.9167H5.20833Z" fill="#4CA9FF"/>
        </Svg>
    );

export default Icon;
