import React, {ReactNode, useCallback, useEffect, useRef} from 'react';
import styled from "styled-components";
import bg from '../../assets/images/modalBG.png'
import {CloseIcon} from "../Svg";

interface ModalProps {
  children: ReactNode,
  title: string,
  desc?: string,
  onClose: () => void,
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1000000;
  padding: 0;
  background: rgba(18, 18, 18, 0.5);
  backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBlock = styled.div`
  position: relative;
  box-sizing: border-box;
  max-height: 80%;
  max-width: 538px;
  width: 100%;
  padding: 53px 43px 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 29px;
  background: rgba(37, 37, 40, 0.50);
  backdrop-filter: blur(25px);

`

const ModalTitle = styled.h4`
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
  color: ${({theme}) => theme.colors.white};
`

const ModalDesc = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: ${({theme}) => theme.colors.border};
  margin-top: 7px;
`

const ScrollWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
`

const Content = styled.div`
  width: 100%;
  margin-top: 56px;
  flex: 1;

  ${({theme}) => theme.mediaQueries.md} {
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  padding: 0;
  cursor: pointer;
`


function Modal({children, title, onClose, desc, ...props}: ModalProps) {
  const wrapper = useRef<HTMLDivElement | null>(null);

  const body = document.querySelector('body');

  useEffect(() => {
    if (body) body.style.overflow = 'hidden';

    return () => {
      if (body) body.style.overflow = 'auto';
    };
  }, [body]);

  const onDismissHandler = useCallback((e: any) => {
    if (wrapper.current === e.target) onClose()
  }, [onClose])

  useEffect(() => {
    const element = wrapper.current
    if (element) {
      element.addEventListener('click', onDismissHandler, false);
      return () => {
        element.removeEventListener('click', onDismissHandler, false);
      };
    }
  }, [onDismissHandler, wrapper]);
  return (
    <ModalWrapper ref={wrapper} {...props}>
      <ModalBlock>
        <CloseButton onClick={onClose}>
          <CloseIcon/>
        </CloseButton>
        <ModalTitle>
          {title}
        </ModalTitle>
        {desc && <ModalDesc>
          {desc}
        </ModalDesc>}
        <ScrollWrapper>
          <Content>
            {children}
          </Content>
        </ScrollWrapper>
      </ModalBlock>
    </ModalWrapper>
  );
}

export default Modal;
