import React, {ReactNode} from 'react';
import styled from "styled-components";

interface RowItemProps {
    item: string | ReactNode
    isSelected: boolean;
    isActive: boolean;
    value: string | number;
    onSelect?: (value: string | number) => void;
    onShow?: () => void,
}

const RowItemStyled = styled.div<{ isActive: boolean, isSelected: boolean }>`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  padding: 7px 14px;
  font-weight: 500;
  min-width: max-content;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 16px;
  border-radius: 6px;
  color: ${({theme}) => theme.colors.headingBlack};
  cursor: pointer;

  &:hover {
    background-color: rgba(7, 7, 8, 0.1);
  }
`

function RowItem({item, value, onSelect, isSelected, isActive, onShow}: RowItemProps) {

    return (
        <RowItemStyled isActive={isActive} isSelected={isSelected}
                       onClick={() => {
                           if (onSelect && (!isSelected)) {
                               onSelect(value)
                           } else if (onShow) {
                               onShow()
                           }
                       }}>
            {item}
        </RowItemStyled>
    );
}

export default RowItem;
