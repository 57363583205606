export enum EAlertVariants {
    Success,
    Info,
    Error,
}

export type AlertThemeVariant = {
    background: string;
    icon: string
    text: string
};

export type AlertTheme = {
    [key in EAlertVariants]: AlertThemeVariant;
};

export type IAlert = {
    variant: EAlertVariants;
    title: string,
    content: string
    trx?: string
    externalLink?: string
    link?: string
};
