import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="29" height="29" viewBox="0 0 29 29" fill="none" {...props} >
            <path d="M24.97 17.5572C24.4798 17.8568 23.9079 17.9112 23.4722 17.6662C22.9276 17.3394 22.6008 16.6041 22.6008 15.5965V12.5192C22.6008 11.0487 22.0289 9.98664 21.0485 9.71432C19.3874 9.22413 18.1347 11.2666 17.6717 12.0291L14.7306 16.7403V10.9398C14.7034 9.60539 14.2677 8.81565 13.4507 8.57056C12.9061 8.40717 12.0891 8.48887 11.2994 9.68709L4.73636 20.1988C3.86493 18.5376 3.40198 16.6858 3.40198 14.8068C3.40198 8.46163 8.49442 3.31472 14.7306 3.31472C20.9668 3.31472 26.0593 8.46163 26.0593 14.8068V14.834V14.8612C26.1137 16.0867 25.7325 17.067 24.97 17.5572ZM28.5646 14.8068V14.7795V14.7523C28.5374 7.04555 22.3284 0.754883 14.7306 0.754883C7.10558 0.754883 0.896606 7.04555 0.896606 14.8068C0.896606 22.5407 7.10558 28.8586 14.7306 28.8586C18.2436 28.8586 21.5659 27.5243 24.1258 25.1006C24.6432 24.6104 24.6704 23.8207 24.1802 23.3033C23.7173 22.7858 22.9276 22.7586 22.4101 23.2215C22.4101 23.2215 22.4101 23.2215 22.3829 23.2488C20.3132 25.2095 17.5356 26.326 14.6762 26.326C11.3266 26.326 8.33103 24.8555 6.26137 22.5135L12.1708 13.0367V17.4211C12.1708 19.518 12.9878 20.1988 13.6686 20.3894C14.3494 20.58 15.3842 20.4439 16.5007 18.6738L19.7414 13.4179C19.8503 13.2545 19.932 13.0911 20.0409 12.9822V15.651C20.0409 17.6117 20.8307 19.1912 22.1923 19.9537C23.4177 20.6617 24.97 20.58 26.2499 19.7903C27.8294 18.7554 28.6736 16.9581 28.5646 14.8068Z" fill="white"/>
        </Svg>
    );

export default Icon;
