import { BigNumber } from "@ethersproject/bignumber";
import {Token} from "@pancakeswap/sdk";

enum ChainId {
    MAINNET = 56,
    TESTNET = 1,
    TON = 0,
}

const mainUrl = `${window.origin}/`

const defaultChainId = ChainId.MAINNET;
const storageConnectorKey = 'wednesday_connector';
const BN_0 = BigNumber.from('0')
export const BASE_BSC_SCAN_URLS: {[chainId in ChainId | string]: string} = {
    [ChainId.TESTNET]: 'https://etherscan.io',
    [ChainId.MAINNET]: 'https://bscscan.com',
    [ChainId.TON]: 'https://testnet.tonviewer.com',
};

export const WBNB = new Token(
  ChainId.MAINNET,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB'
);
export const WETH = new Token(
  ChainId.TESTNET,
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  18,
  'WETH'
);

export const BUSD = new Token(
  ChainId.MAINNET,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD'
);


export const USDT = new Token(
  ChainId.TESTNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT'
);



export {defaultChainId, storageConnectorKey, ChainId, BN_0, mainUrl};
