import {Colors} from "./types";

export const baseColors = {
    black: '#000',
    background: '#111113',
    white: '#fff',
    heading: '#D9D9D9',
    borderHeading: 'rgba(217,217,217,0.1)',
    border: 'rgba(255,255,255,0.3)',
    borderLight: 'rgba(255,255,255,0.1)',
    border20: 'rgba(255,255,255,0.2)',
    text: 'rgba(255,255,255,0.5)',
    shadowYellow: '#FFE27E',
    headingBlack: '#080808',
    teamHeading: '#6A6A6A',
    input: '#404040',
    gray: '#1B1B1F',
    up: '#5CB789',
    down: '#D8574E',
    iconBg: '#3E3E3E',
    primary: '#4CBFFF',
    iconSuccess: '#27AE60',
    successBorder: 'rgba(39, 174, 96, 0.2)',
    successBg: 'rgba(39,174,96,0.1)',
    alertBg: 'rgba(63,63,63,0.1)',
    alertBgError: 'rgba(255,68,24,0.1)',
    card: 'rgba(37,37,40,0.5)',
};


export const darkColors: Colors = {
    ...baseColors,
};
