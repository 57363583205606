import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24"  fill="none" {...props} >
          <path d="M6.4 18.5L5 17.1L14.6 7.5H6V5.5H18V17.5H16V8.9L6.4 18.5Z" fill="white"/>
        </Svg>
    );

export default Icon;
