import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {AppDispatch, RootState} from "../index";
import {
  addAsset,
  addTransaction,
  changeStep,
  confirmWallet,
  logout,
  removeTransaction,
  selectWallet
} from "./walletSlice";
import fetchAssetsBalance from "./fetchAssetsBalance";
import fetchTransactions from "./fetchTransactions";
import useBlockNumber from "../../hooks/useBlockNumber";
import {IAsset} from "../../config/assets";
import fetchAssetsChanges from "./fetchAssetsChanges";
import fetchRestoreWallet from "./fetchRestoreWallet";
import fetchCreateWallet from "./fetchCreateWallet";
import {ITransaction} from "./types";

export const getWallet = (state: RootState) => state.wallet

export function useWalletAction() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const blockNumber = useBlockNumber(2)

  const onFetchAssetsChanges = useCallback(() => {
    dispatch(fetchAssetsChanges())
  }, [dispatch])
  const onFetchAssetsBalance = useCallback(() => {
    dispatch(fetchAssetsBalance())
      .unwrap()
      .then(() => onFetchAssetsChanges())
  }, [dispatch, onFetchAssetsChanges])
  const onFetchTransactions = useCallback(() => {
    dispatch(fetchTransactions())
  }, [dispatch, blockNumber])

  const onRestoreWallet = useCallback((mnemonic: string[]) => {
    dispatch(fetchRestoreWallet(mnemonic))
    navigate('/wallet')
  }, [dispatch, navigate])

  const onCreateWallet = useCallback(() => {
    dispatch(fetchCreateWallet())
  }, [dispatch])

  const onAddAsset = useCallback((asset: IAsset) => {
    dispatch(addAsset(asset))
  }, [dispatch])

  const onAddTransaction = useCallback((tx: ITransaction) => {
    dispatch(addTransaction(tx))
  }, [dispatch])

  const onRemoveTransaction = useCallback((timestamp: number) => {
    dispatch(removeTransaction(timestamp))
  }, [dispatch])

  const onSelectWallet = useCallback((id: number) => {
    dispatch(selectWallet(id))
  }, [dispatch])

  const onLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const onConfirmWallet = useCallback(() => {
    dispatch(confirmWallet())
    navigate('/wallet')
  }, [dispatch])

  const onChangeStep = useCallback((step: number) => {
    dispatch(changeStep(step))
  }, [dispatch])

  return useMemo(() => ({
    onCreateWallet,
    onLogout,
    onChangeStep,
    onConfirmWallet,
    onFetchAssetsBalance,
    onRestoreWallet,
    onFetchTransactions,
    onAddAsset,
    onFetchAssetsChanges,
    onSelectWallet,
    onAddTransaction,
    onRemoveTransaction,
  }), [
    onCreateWallet,
    onAddTransaction,
    onRemoveTransaction,
    onLogout,
    onChangeStep,
    onConfirmWallet,
    onFetchAssetsBalance,
    onRestoreWallet,
    onFetchTransactions,
    onAddAsset,
    onFetchAssetsChanges,
    onSelectWallet,
  ])
}

export function useWalletState() {
  return useSelector(getWallet)
}

export function useAssetChanges() {
  const {onFetchAssetsChanges} = useWalletAction()
  const blockNumber = useBlockNumber(10)
  useEffect(() => {
    // onFetchAssetsChanges()
  }, [blockNumber])
}
