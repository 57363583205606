import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} >
          <path d="M13.2044 23.0769L14.148 22.1179L8.69673 16.6667H25.8711V15.3333H8.69673L14.148 9.88207L13.2044 8.92307L6.12749 16L13.2044 23.0769Z" fill="white"/>
        </Svg>
    );

export default Icon;
