import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Container} from "../Templates";
import logo from '../../assets/images/Logo.webp'
import {Button, EButtonVariants} from "../../UI/Button";
import {shortAddress} from "../../utils";
import money from "../../assets/images/Money.webp";
import {useAppState} from "../../store/app/hooks";
import {useWalletAction, useWalletState} from "../../store/wallet/hooks";
import {LogoutIcon, TokenIcon} from "../../UI/Svg";

const Navbar = styled.nav<{ isScrolled: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${({theme, isScrolled}) => isScrolled ? theme.colors.background : 'transparent'};
  @media (min-width: 1200px) {
    padding: 11px 0 28px;
  }
`

const ContainerStyled = styled(Container)`
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

const LeftBlock = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
`

const Logo = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 58.562px;
    height: 59.98px;

    ${({theme}) => theme.mediaQueries.md} {
      width: 41px;
      height: 41px;
    }
  }
`

const MobileButton = styled.div<{ isShow: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-start;
  position: relative;
  width: 26px;
  min-height: 10px;

  span {
    width: 26px;
    height: 3px;
    background: ${({theme}) => theme.colors.white};
    position: absolute;
    left: 0;
    top: 0;

    ${({isShow}) => isShow ? `
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotateZ(45deg);` : ''}
    &:nth-child(2) {
      left: unset;
      top: unset;
      right: 0;
      bottom: 0;
      opacity: ${({isShow}) => isShow ? 1 : 0.4};
      width: ${({isShow}) => isShow ? '26px' : '10px'};
      ${({isShow}) => isShow ? `
      right: unset;
      bottom: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) rotateZ(135deg);` : ''}
    }
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const MobileMenu = styled.div<{ isShow: boolean }>`
  visibility: ${({isShow}) => isShow ? 'visible' : 'hidden'};
  opacity: ${({isShow}) => isShow ? '1' : '0'};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(22px);
  z-index: 100;
`

const MobileNav = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 100px;
  gap: 25px;
`

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
`

const RightBlock = styled.div`
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 21px;
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;

  ${InfoBlock} {
    display: none;
  }

  @media (min-width: 1200px) {

    ${InfoBlock} {
      display: flex;
    }
  }
`

const InfoIcon = styled.div`
  min-width: 46.5px;
  height: 46.5px;
  border-radius: 50%;
  position: relative;
  background: ${({theme}) => theme.colors.gray};

  img {
    position: absolute;
  }
`

const MoneyImg = styled.img`
  width: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const InfoDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`

const InfoLabel = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`

const InfoValue = styled.div`
  color: ${({theme}) => theme.colors.text};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  white-space: nowrap;
  align-items: baseline;
  gap: 12px;
  letter-spacing: -1.08px;
  text-transform: uppercase;
`


const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16.57px;

  ${({theme}) => theme.mediaQueries.md} {
    gap: 5.6px;
  }
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Title = styled.h3`
  font-size: 28.728px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${({theme}) => theme.mediaQueries.md} {
    font-size: 20px;
  }
`

const ButtonStyled = styled(Button)`
  padding: 12px 20px;
  gap: 11px;
  font-size: 14px;

  svg {
    width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }

`

const ButtonMobile = styled(ButtonStyled)`
  padding: 12px 10px;
  gap: 4px;
  font-size: 14px;
  display: none;

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${({theme}) => theme.mediaQueries.md} {
    display: flex;
  }
`

function Index() {
  const {walletData} = useWalletState()
  const {onLogout} = useWalletAction()

  return (
    <Navbar isScrolled={false}>
      <ContainerStyled>
        <LogoBlock>
          <Logo to="/">
            <img src={logo} alt="Addams"/>
          </Logo>
          <TitleBlock>
            <Title>$ADDAMS</Title>
          </TitleBlock>
        </LogoBlock>
        <RightBlock>
          {walletData ? <><ButtonStyled variant={EButtonVariants.Secondary} onClick={() => onLogout()} ><LogoutIcon/> Logout</ButtonStyled>
            <ButtonMobile variant={EButtonVariants.Secondary} onClick={() => onLogout()}><LogoutIcon/> Logout</ButtonMobile> </>
          : <><ButtonStyled variant={EButtonVariants.Secondary} as='a'
                            href='https://app.uniswap.org/tokens/eth/0xddbb3e6f8413d0e3adc700a731da304aec97bcbb'
                            rel='noreferrer' target='_blank'><TokenIcon/> Buy ADDAMS</ButtonStyled>
              <ButtonMobile variant={EButtonVariants.Secondary} as='a'
                            href='https://app.uniswap.org/tokens/eth/0xddbb3e6f8413d0e3adc700a731da304aec97bcbb'
                            rel='noreferrer' target='_blank'><TokenIcon/> Buy</ButtonMobile> </>}
        </RightBlock>
      </ContainerStyled>
    </Navbar>
  );
}

export default Index;
