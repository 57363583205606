import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="31" height="31" viewBox="0 0 31 31" fill="none" {...props} >
          <path d="M7.7487 25.8334C6.32786 25.8334 5.11155 25.3275 4.09974 24.3157C3.08793 23.3039 2.58203 22.0876 2.58203 20.6667V10.3334C2.58203 8.91258 3.08793 7.69626 4.09974 6.68446C5.11155 5.67265 6.32786 5.16675 7.7487 5.16675H23.2487C24.6695 5.16675 25.8859 5.67265 26.8977 6.68446C27.9095 7.69626 28.4154 8.91258 28.4154 10.3334V20.6667C28.4154 22.0876 27.9095 23.3039 26.8977 24.3157C25.8859 25.3275 24.6695 25.8334 23.2487 25.8334H7.7487ZM7.7487 10.3334H23.2487C23.7223 10.3334 24.1744 10.3872 24.6049 10.4949C25.0355 10.6025 25.4445 10.7747 25.832 11.0115V10.3334C25.832 9.623 25.5791 9.01484 25.0732 8.50894C24.5673 8.00303 23.9591 7.75008 23.2487 7.75008H7.7487C7.03828 7.75008 6.43012 8.00303 5.92422 8.50894C5.41832 9.01484 5.16536 9.623 5.16536 10.3334V11.0115C5.55286 10.7747 5.96189 10.6025 6.39245 10.4949C6.823 10.3872 7.27509 10.3334 7.7487 10.3334ZM5.35911 14.5313L19.7289 18.0188C19.9227 18.0619 20.1164 18.0619 20.3102 18.0188C20.5039 17.9758 20.6869 17.8897 20.8591 17.7605L25.3477 14.0147C25.1109 13.6917 24.8095 13.428 24.4435 13.2235C24.0775 13.019 23.6793 12.9167 23.2487 12.9167H7.7487C7.18898 12.9167 6.69922 13.0621 6.27943 13.3527C5.85964 13.6433 5.55286 14.0362 5.35911 14.5313Z" fill="#7C7A81"/>
        </Svg>
    );

export default Icon;
