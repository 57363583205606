import React from 'react';
import styled from "styled-components";
import {UpdateIcon} from "../../UI/Svg";
import {useWalletAction, useWalletState} from "../../store/wallet/hooks";
import SelectFundComponent from "../../components/WalletComponents/SelectFundComponent";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`

const TitleBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

const UpdateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ListWrapper = styled.div`
  overflow: auto;
  width: 100%;
  padding: 3px;
`

const List = styled.div`
  display: flex;
  gap: 10px;
  align-items: stretch;
`


function FundsView() {
  const {assets, selectedWalletId} = useWalletState()
  const {onSelectWallet, onFetchAssetsBalance} = useWalletAction()
  return (
    <Wrapper>
      <TitleBlock>
        Your funds
        <UpdateButton onClick={onFetchAssetsBalance}>
          <UpdateIcon/>
        </UpdateButton>
      </TitleBlock>
      <ListWrapper>
        <List>
          {
            assets.map((fund, id) => <SelectFundComponent onSelect={val => onSelectWallet(val)}
                                                          isSelected={fund.chainId === selectedWalletId} key={id}
                                                          fund={fund}/>)
          }
        </List>
      </ListWrapper>
    </Wrapper>
  );
}

export default FundsView;
