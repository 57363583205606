import React, {useMemo} from 'react';
import styled from "styled-components";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {CopyIcon, DropdownIcon, WalletIcon, WithdrawIcon} from "../../UI/Svg";
import {useWalletAction, useWalletState} from "../../store/wallet/hooks";
import {EAlertVariants} from "../../UI/Alert/types";
import {useAppAction} from "../../store/app/hooks";
import useTotalBalance from "../../hooks/useTotalBalance";
import {Button, EButtonVariants} from "../../UI/Button";
import {EModal} from "../../store/app/types";

const Wrapper = styled.div`
  width: 100%;
  background: ${({theme}) => theme.colors.card};
  border-radius: 30px;
  padding: 44px 40px 30px;

  ${({theme}) => theme.mediaQueries.md} {
    padding: 20px 14px 24px;
  }
`

const AddressBlock = styled.div`
  width: 100%;
  position: relative;
  border-radius: 14px;
  background: rgba(80, 80, 93, 0.35);
  backdrop-filter: blur(57px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 9px 11px;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 4px;

  & > svg {
    min-width: 24px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
  overflow: hidden;

  svg {
    min-width: 31px;
  }
`

const Address = styled.div`
  text-overflow: ellipsis;
  color: ${({theme}) => theme.colors.white};

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`


const BalanceRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin-top: 12px;

  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`

const Label = styled.div`
  color: ${({theme}) => theme.colors.border};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
`

const WalletBalance = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
  flex-direction: column;

`

const BalanceValue = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
`

const ChangeBlock = styled.div`
  border-radius: 19px;
  background: rgba(51, 51, 62, 0.35);
  backdrop-filter: blur(57px);
  padding: 17px 31px 21px 41px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  justify-content: flex-start;

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    padding: 14px 10px 14px;
    margin-top: 12px;
  }
`

const ChangeAmount = styled.div`
  color: ${({theme}) => theme.colors.white};
  text-align: right;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 102%;
`
const ChangeValue = styled.div<{ isUp: number }>`
  color: ${({theme, isUp}) => isUp > 0 ? '#49F29E' : isUp < 0 ? '#f24949' : theme.colors.text};
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 102%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1px;

  svg {
    ${({isUp}) => isUp ? `transform: rotateZ(180deg)` : ''};

    * {
      opacity: 1;
    }

    width: 24px;
    height: 24px;

    path {
      fill: ${({theme, isUp}) => isUp > 0 ? '#49F29E' : isUp < 0 ? '#f24949' : theme.colors.text};
    }
  }
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  gap: 13px;
  margin-top: 56px;
  flex-wrap: nowrap;

  & > * {
    flex: 1;
    max-width: unset;
  }

  ${({theme}) => theme.mediaQueries.md} {
    margin-top: 20px;
  }
`

const ButtonStyled = styled(Button)`
  padding: 19px 30px;

  svg {
    path {
      //stroke: #1C1B1F;
      fill: #1C1B1F;
    }
  }

  ${({theme}) => theme.mediaQueries.md} {
    padding: 19px 25px;
    gap: 8px;
    font-size: 14px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`

function WalletMainView() {
  const {walletData, selectedWalletId} = useWalletState()
  const {onAlertOpen, onModalOpen} = useAppAction()
  const {onFetchAssetsBalance} = useWalletAction()
  const {assets} = useWalletState()

  const totalChange = useMemo(() => {
    return assets.reduce((acc, item) => item.balance ? acc + (item.change || 0) : acc, 0)
  }, [assets])

  const {usdBalance} = useTotalBalance()
  return (
    <Wrapper>
      <CopyToClipboard onCopy={() => {
        onAlertOpen({variant: EAlertVariants.Info, title: 'Copy', content: 'Address was copied'})
      }} text={walletData.address || ''}>
        <AddressBlock>
          <LeftBlock>
            <WalletIcon/>
            {walletData && <Address>{walletData.address}</Address>}
          </LeftBlock>
          <CopyIcon/>
        </AddressBlock>
      </CopyToClipboard>
      <BalanceRow>
        <WalletBalance>
          <Label>your wallet</Label>
          <BalanceValue>${usdBalance.toFixed(4)}</BalanceValue>
        </WalletBalance>
        <ChangeBlock>
          <ChangeAmount>${usdBalance.toFixed(4)}</ChangeAmount>
          <ChangeValue isUp={totalChange}>
            {totalChange.toFixed(2)}%
            {totalChange !== 0 && <DropdownIcon/>}
          </ChangeValue>
        </ChangeBlock>
      </BalanceRow>
      <Buttons>
        <ButtonStyled onClick={() => {
          if (selectedWalletId !== null) {
            onModalOpen(EModal.Deposit)
          } else {
            onAlertOpen({variant: EAlertVariants.Info, title: "Deposit", content: 'Select Fund below'})
          }
        }} variant={EButtonVariants.White}>
          <WalletIcon/>
          Deposit
        </ButtonStyled>
        <ButtonStyled onClick={() => onModalOpen(EModal.Output)} variant={EButtonVariants.White}>
          <WithdrawIcon/>
          Withdraw
        </ButtonStyled>
      </Buttons>
    </Wrapper>
  );
}

export default WalletMainView;
