import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {IAsset} from "../../config/assets";
import {getBalanceTon, getJettonBalance} from "../../utils/ton";


export const getBalance = async (asset: IAsset, addr: string,): Promise<IAsset> => {
  try {

    let assetBalance = 0;
    if (asset.symbol === 'TON') assetBalance = await getBalanceTon(addr)
    else assetBalance = Number(await getJettonBalance(addr, asset.address));
    return {...asset, balance: assetBalance}
  } catch (e) {
    console.log(e, asset, addr, asset.chainId === 0);
    return {...asset, balance: 0}
  }
}

const fetchAssetsBalance = createAsyncThunk<IAsset[],
  void,
  {
    state: RootState
  }>(
  'wallet/fetchAssetsBalance',
  async (_, ThunkApi) => {
    const {wallet: {assets, walletData}} = ThunkApi.getState()
    if (walletData && assets.length > 0) {
      const data: Promise<IAsset>[] = []
      try {
        for (const asset of assets) {
          data.push(getBalance(asset, walletData.address))
        }
        return await Promise.all(data)
      } catch (e) {
        console.log(e)
        return assets
      }
    }
    return assets
  }
)

export default fetchAssetsBalance
