import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props} >
          <path d="M7.23999 12.5764L12.5754 9.14648L7.23999 5.71655V12.5764ZM9.14551 16.7686C8.09112 16.7686 7.10025 16.5685 6.1729 16.1683C5.24555 15.7682 4.43888 15.2251 3.75289 14.5391C3.06691 13.8531 2.52383 13.0464 2.12368 12.1191C1.72352 11.1917 1.52344 10.2009 1.52344 9.14648C1.52344 8.0921 1.72352 7.10123 2.12368 6.17388C2.52383 5.24653 3.06691 4.43986 3.75289 3.75387C4.43888 3.06788 5.24555 2.52481 6.1729 2.12465C7.10025 1.72449 8.09112 1.52441 9.14551 1.52441C10.1999 1.52441 11.1908 1.72449 12.1181 2.12465C13.0455 2.52481 13.8521 3.06788 14.5381 3.75387C15.2241 4.43986 15.7672 5.24653 16.1673 6.17388C16.5675 7.10123 16.7676 8.0921 16.7676 9.14648C16.7676 10.2009 16.5675 11.1917 16.1673 12.1191C15.7672 13.0464 15.2241 13.8531 14.5381 14.5391C13.8521 15.2251 13.0455 15.7682 12.1181 16.1683C11.1908 16.5685 10.1999 16.7686 9.14551 16.7686ZM9.14551 15.2441C10.8478 15.2441 12.2896 14.6534 13.471 13.472C14.6525 12.2906 15.2432 10.8487 15.2432 9.14648C15.2432 7.44422 14.6525 6.00238 13.471 4.82096C12.2896 3.63954 10.8478 3.04883 9.14551 3.04883C7.44325 3.04883 6.0014 3.63954 4.81998 4.82096C3.63856 6.00238 3.04785 7.44422 3.04785 9.14648C3.04785 10.8487 3.63856 12.2906 4.81998 13.472C6.0014 14.6534 7.44325 15.2441 9.14551 15.2441Z" fill="white"/>
        </Svg>
    );

export default Icon;
