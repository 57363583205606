import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...props} >
          <path d="M8.63987 35.0208C7.77693 35.0208 7.04652 34.7218 6.44863 34.1239C5.85072 33.526 5.55176 32.7956 5.55176 31.9326V5.125H8.11417V31.9326C8.11417 32.0641 8.16894 32.1845 8.27847 32.2941C8.38798 32.4036 8.50844 32.4583 8.63987 32.4583H35.4475V35.0208H8.63987ZM11.4324 29.4687V15.5393H16.5573V29.4687H11.4324ZM19.547 29.4687V6.99759H24.6719V29.4687H19.547ZM27.6615 29.4687V22.3726H32.7864V29.4687H27.6615Z" fill="white"/>
        </Svg>
    );

export default Icon;
