import React from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import styled from "styled-components";
import classes from "../../components/CreateWalletComponents/View/View.module.scss";
import {useWalletAction, useWalletState} from "../../store/wallet/hooks";
import {ArrowNextIcon, CopyIcon} from "../../UI/Svg";
import {Button, EButtonVariants} from "../../UI/Button";
import {useAppAction} from "../../store/app/hooks";
import {EAlertVariants} from "../../UI/Alert/types";

const CopyButton = styled.button`
  background: transparent;
  background: -webkit-linear-gradient(#4CA9FF, #4C89FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFF;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`

const ButtonStyled = styled(Button)`
  max-width: 342px;
  padding: 20px 60px;
  width: fit-content;
  margin-top: 48px;

  span {
    line-height: inherit;
    font-size: 18px;

    background: transparent;
    background: -webkit-linear-gradient(#4CA9FF, #4C89FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
  }
`

function StepSecondView() {

  const {mnemonic} = useWalletState()
  const {onChangeStep} = useWalletAction()
  const {onAlertOpen} = useAppAction()

  return (
    <div className={[classes.viewTab, classes.two, classes.active, ''].join(' ')}>
      <div className={[classes.viewTabContent, ''].join(' ')}>
        <h2 className={[classes.viewTabContentTitle, ''].join(' ')}>
          Your seed phrase
        </h2>
        <p className={[classes.viewTabContentText, ''].join(' ')}>
          Write these words in the correct order and keep them in a safe place.
        </p>
        <div className={[classes.viewTabContentRow, ''].join(' ')}>
          {mnemonic.split(' ').map((word, id) =>
            (
              <div
                key={id}
                className={[classes.viewTabContentRowWord, ''].join(' ')}
              >
                <span>{id + 1 < 10 && 0}{id + 1}</span>
                {word}
              </div>
            )
          )}
        </div>
        <CopyToClipboard text={mnemonic} onCopy={() => onAlertOpen({
          title: 'Copy', variant: EAlertVariants.Info, content: 'Seed phrase was copied'
        })}>
          <CopyButton>
            <CopyIcon/>
            Copy all phrase
          </CopyButton>
        </CopyToClipboard>
        <ButtonStyled variant={EButtonVariants.White} onClick={() => onChangeStep(3)}>
          <span>Next</span>
          <ArrowNextIcon/>
        </ButtonStyled>
      </div>
    </div>
  );
}

export default StepSecondView;
