import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props} >
          <path d="M10.9991 20.1506C10.7158 20.1506 10.4783 20.0548 10.2866 19.8631C10.0949 19.6714 9.9991 19.4339 9.9991 19.1506V13.1506L4.1991 5.7506C3.9491 5.41727 3.9116 5.06727 4.0866 4.7006C4.2616 4.33394 4.56577 4.1506 4.9991 4.1506H18.9991C19.4324 4.1506 19.7366 4.33394 19.9116 4.7006C20.0866 5.06727 20.0491 5.41727 19.7991 5.7506L13.9991 13.1506V19.1506C13.9991 19.4339 13.9033 19.6714 13.7116 19.8631C13.5199 20.0548 13.2824 20.1506 12.9991 20.1506H10.9991ZM11.9991 12.4506L16.9491 6.1506H7.0491L11.9991 12.4506Z" fill="white"/>
        </Svg>
    );

export default Icon;
