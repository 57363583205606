import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <path d="M7.4 18.4L6 17L12 11L18 17L16.6 18.4L12 13.825L7.4 18.4ZM7.4 12.4L6 11L12 5L18 11L16.6 12.4L12 7.825L7.4 12.4Z" fill="white"/>
        </Svg>
    );

export default Icon;
