import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props} >
            <g opacity="0.4">
                <path d="M8.50016 10.625L4.9585 7.08333H12.0418L8.50016 10.625Z" fill="white"/>
            </g>
        </Svg>
    );

export default Icon;
