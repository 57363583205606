import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import RowItem from './RowItem';
import {DropdownIcon} from "../Svg";


interface DropdownProps {
  value: string,
  placeholder: string | ReactNode,
  onSelect: (val: any) => void,
  options: Array<{ item: string | ReactNode, value: string | number }>,
  label?: string,
  icon?: ReactNode
}

const DropdownStyled = styled.div`
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  z-index: 10;
  width: 100%;
  ${({theme}) => theme.mediaQueries.md} {
    width: unset;
  }
`

const LabelStyled = styled.label`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: rgba(6, 6, 8, 0.5);
  margin-right: 10px;
  margin-bottom: 4px;
`
const RowStyled = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  gap: 35px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 14px 13px 14px 15px;
  min-width: 145px;
  background: transparent;
  color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.border};
  width: 100%;
  border-radius: 18px;
  ${({theme}) => theme.mediaQueries.md} {
  }
`

const IconStyled = styled.div<{ showMenu: boolean }>`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;

    g {
      opacity: 1;
    }

    path {
      fill: ${({theme}) => theme.colors.white};
    }
  }

  ${({showMenu}) => showMenu ? `svg {
    transform: rotateZ(-180deg);
  };` : ''}
`
const Menu = styled.div<{ showMenu: boolean, menuPositionLeft: boolean }>`
  visibility: ${({showMenu}) => showMenu ? 'visible' : 'hidden'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 14px 10px;
  position: absolute;
  border-radius: 8px;
  gap: 10px;
  top: 100%;
  left: ${({menuPositionLeft}) => menuPositionLeft ? '0px' : 'unset'};
  box-sizing: border-box;
  right: 0;
  margin-top: 5px;
  transition: all .25s ease-out;
  opacity: ${({showMenu}) => showMenu ? 1 : 0};
  z-index: 100;
  background-color: rgba(37, 37, 40);
  height: ${({showMenu}) => showMenu ? 'fit-content' : 0};
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  box-shadow: 0 0 2px 2px rgba(34, 34, 34, .2);
`

const Placeholder = styled.div<{ showMenu: boolean }>`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 80%;
  letter-spacing: -0.04em;
  color: ${({theme}) => theme.colors.white};
  cursor: pointer;
`

function Dropdown({value, placeholder, onSelect, label, options, icon, ...props}: DropdownProps) {
  const dropdown = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement | null>(null)
  const [menuPositionLeft, setMenuPositionLeft] = useState<boolean>(true);

  const selectItem = useMemo(() => options.filter(item => item.value === value)[0] || null, [options, value])

  const handleClickOutside = useCallback((e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(dropdown.current)) {
      setShowMenu(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (!showMenu) {
      if (menu.current !== null) {
        const menuRight = menu.current?.getBoundingClientRect().right
        const windowWidth = window.innerWidth
        setMenuPositionLeft(windowWidth - menuRight > 30)
      } else {
        setMenuPositionLeft(true)
      }
    }
  }, [menu, showMenu])

  const onSelectHandler = useCallback((val: string | number) => {
    onSelect(val)
    setShowMenu(false)
  }, [onSelect])

  const [selectItemItem] = useMemo(() => {
    if (selectItem) {
      return [selectItem.item, selectItem.value]
    }
    return [placeholder, '-']

  }, [selectItem, placeholder])

  const onToggleShow = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])
  return (
    <DropdownStyled ref={dropdown} {...props}>
      {
        label && <LabelStyled>{label}</LabelStyled>
      }
      <RowStyled onClick={onToggleShow}>
        {
          selectItem
            ? <Placeholder showMenu={showMenu}>
              {icon}
              {selectItemItem}
            </Placeholder>
            : <Placeholder showMenu={showMenu}>
              {icon}
              {placeholder}
            </Placeholder>
        }
        <IconStyled showMenu={showMenu}>
          <DropdownIcon/>
        </IconStyled>
      </RowStyled>
      <Menu menuPositionLeft={menuPositionLeft} ref={menu} showMenu={showMenu}>
        {
          options.map((opt, id) => {
            const isSelected = selectItem?.value === opt.value

            return (
              <RowItem isActive={false} isSelected={isSelected}
                       key={id} onSelect={onSelectHandler} item={opt.item} value={opt.value}/>
            )
          })
        }
      </Menu>
    </DropdownStyled>
  );
}

export default Dropdown;
