import {AnchorHTMLAttributes, ButtonHTMLAttributes} from "react";

export enum EButtonVariants {
    Primary,
    Secondary,
    White,
}


type ButtonTypes = ButtonHTMLAttributes<HTMLButtonElement> | AnchorHTMLAttributes<HTMLAnchorElement> | any;

export type ButtonProps = {
    variant: EButtonVariants;
    fullWidth?: boolean;
    as?: "a" | "button";
    href?: string;
    external?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
} & ButtonTypes;

export type ButtonThemeVariant = {
    color: string;
    background: string;
    svgColor: string
};

export type ButtonTheme = {
    [key in EButtonVariants]: ButtonThemeVariant;
};
