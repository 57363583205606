import ethImg from "../assets/images/eth.png";
import bscImg from "../assets/images/bsc.png";
import tonImg from "../assets/images/Ton.svg";

export interface IAsset {
  name: string;
  symbol: string;
  chainId: number;
  decimals: number;
  address?: string,
  logoURI: string;
  balance?: number;
  usdBalance?: number;
  change?: number;
}

export const assets: Array<IAsset> = [
  {
    name: 'Ton',
    symbol: 'TON',
    chainId: 0,
    address: '',
    decimals: 9,
    change: 0,
    logoURI: tonImg,
  },
]
