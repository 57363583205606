import React, {forwardRef, ReactNode, useRef} from 'react';
import styled from "styled-components";


interface InputProps {
  value: string,
  placeholder: string,
  onChange: (val: any) => void,
  label?: string,
  dopText?: string | ReactNode
  labelDopText?: string | ReactNode
  id?: string,
  error?: string
}

const InputBlock = styled.div`
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const LabelStyled = styled.label`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: ${({theme}) => theme.colors.text};
  margin-right: 10px;
  margin-bottom: 4px;
  padding-left: 10px;
`
const InputStyled = styled.input<{ isError: boolean }>`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: transparent;
  border-radius: 18px;
  padding: 27px;
  min-width: 145px;
  font-style: normal;
  letter-spacing: -0.04em;
  text-align: left;
  outline: none;
  width: 100%;
  color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme, isError}) => isError ? theme.colors.down : theme.colors.border};

  font-size: 20px;
  font-weight: 500;
  line-height: 119%;
  &:focus {
    border-color: ${({theme, isError}) => isError ? theme.colors.down : theme.colors.border};
  }
`

const DopText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({theme}) => theme.colors.text};
  padding-right: 14px;
  margin-top: 8px;
  width: 100%;
  text-align: right;
`

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ErrorBlock = styled.div`
  color: ${({theme}) => theme.colors.down};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  width: 100%;
`
const Input = forwardRef<HTMLInputElement, InputProps>(({
                                                          value,
                                                          placeholder,
                                                          onChange,
                                                          label= '',
                                                          dopText = '',
                                                          labelDopText = '',
                                                          error = '',
                                                          id = '',
                                                          ...props
                                                        }, ref) => {

  const refInput = useRef<HTMLInputElement>(null)

  return (
    <InputBlock {...props}>
      {(label || labelDopText) && <LabelRow>
        {label && <LabelStyled htmlFor={id}>{label}</LabelStyled>}
        {labelDopText}
      </LabelRow>}
      <InputStyled
        id={id}
        ref={refInput}
        isError={!!error}
        value={value}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
      />
      <LabelRow>
        <ErrorBlock>
          {error}
        </ErrorBlock>
        {dopText && <DopText>
          {dopText}
        </DopText>}
      </LabelRow>
    </InputBlock>
  );
})

export default Input;
