import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";
import {ITransaction, TTonWallet} from "./types";
import {assets, IAsset} from "../../config/assets";
import fetchAssetsBalance from "./fetchAssetsBalance";
import fetchTransactions from "./fetchTransactions";
import fetchAssetsChanges from "./fetchAssetsChanges";
import fetchCreateWallet from "./fetchCreateWallet";
import fetchRestoreWallet from "./fetchRestoreWallet";

export interface WalletState {
  mnemonic: string,
  walletData: TTonWallet | null
  notCheckWalletData: TTonWallet | null
  step: number
  lastRequestChange?: string
  assets: IAsset[],
  isLoading: boolean
  waitingTransactions: ITransaction[],
  transactions: ITransaction[],
  selectedWalletId: number | null
}

export const initialState: WalletState = {
  mnemonic: '',
  step: 1,
  walletData: null,
  notCheckWalletData: null,
  assets,
  isLoading: false,
  waitingTransactions: [],
  transactions: [],
  selectedWalletId: null
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    selectWallet: (state, action: PayloadAction<number>) => {
      state.selectedWalletId = state.selectedWalletId === action.payload ? null : action.payload
    },
    addAsset: (state, action: PayloadAction<IAsset>) => {
      state.assets = [...assets, action.payload]
    },
    confirmWallet: (state) => {
      state.walletData = {...state.notCheckWalletData}
    },
    addTransaction: (state, action: PayloadAction<ITransaction>) => {
      state.waitingTransactions = [action.payload, ...state.waitingTransactions]
    },
    removeTransaction: (state, action: PayloadAction<number>) => {
      state.waitingTransactions = state.waitingTransactions.filter((tx) => tx.timestamp !== action.payload)
    },
    changeStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    logout: state => {
      state.walletData = null
      state.step = 1
      state.notCheckWalletData = null
      state.mnemonic = ''
      state.assets = []
      state.transactions = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCreateWallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCreateWallet.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCreateWallet.fulfilled, (state, action: PayloadAction<{
        wallet: TTonWallet,
        mnemonic: string
      }>) => {
        state.notCheckWalletData = action.payload.wallet
        state.isLoading = false
        state.mnemonic = action.payload.mnemonic
        state.step = 2
        state.assets = assets
      })
      .addCase(fetchRestoreWallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRestoreWallet.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchRestoreWallet.fulfilled, (state, action: PayloadAction<TTonWallet>) => {
        state.walletData = action.payload
        state.isLoading = false
        state.assets = assets
      })
      .addCase(fetchAssetsBalance.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAssetsBalance.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchAssetsBalance.fulfilled, (state, action: PayloadAction<IAsset[]>) => {
        state.assets = action.payload
        state.isLoading = false
      })
      .addCase(fetchAssetsChanges.fulfilled, (state, action: PayloadAction<IAsset[]>) => {
        state.assets = action.payload
      })
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchTransactions.fulfilled, (state, action: PayloadAction<ITransaction[]>) => {
        const transcations = _.uniqBy([...state.transactions, ...action.payload], 'hash')
          .sort((a, b) => b.timestamp - a.timestamp)
        const trxTimestamps = transcations.map((tx) => tx.timestamp)
        if (state.waitingTransactions.length > 0) {
          state.waitingTransactions = state.waitingTransactions.filter((tx) => {
            const trx = trxTimestamps.filter((item) => item >= tx.timestamp)
            return trx.length === 0
          })
        }
        state.transactions = transcations
        state.isLoading = false
      })
  }
})

export const {logout, addTransaction, removeTransaction, changeStep, confirmWallet, addAsset, selectWallet} = walletSlice.actions
export default walletSlice.reducer
