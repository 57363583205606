import React from 'react';
import styled from "styled-components";
import {ITransaction} from "../../store/wallet/types";
import {TransactionIcon} from "../../UI/Svg";
import {shortAddress} from "../../utils";
import {BASE_BSC_SCAN_URLS} from "../../config";
import {useAppState} from "../../store/app/hooks";
import {useWalletState} from "../../store/wallet/hooks";

const Wrapper = styled.div`
  width: 100%;
  background: ${({theme}) => theme.colors.card};
  border-radius: 29px;
  padding: 27px 34px 26px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: column-reverse;
    padding: 0;
  }
`

const Title = styled.div`
  color: ${({theme}) => theme.colors.white};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin-top: 8px;
  line-height: 80%; /* 17.6px */
  letter-spacing: -0.88px;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 30px;
  }
`

const SymbolBlock = styled.div`
  color: ${({theme}) => theme.colors.border};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  text-align: right;
  ${({theme}) => theme.mediaQueries.md} {
    text-align: center;
    justify-content: center;
  }
`

const BalanceBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  ${({theme}) => theme.mediaQueries.md} {
    width: 100%;
    align-items: center;
    padding: 19px 20px 18px;
    border-bottom: 1px solid ${({theme}) => theme.colors.borderLight};
  }
    
`

const LeftBlock = styled.div`
  display: flex;
  gap: 27px;
  text-align: right;
  align-items: center;
  ${({theme}) => theme.mediaQueries.md} {
    padding: 18px 20px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(217, 217, 217, 0.1);
  width: 71px;
  min-width: 71px;
  height: 71px;
  border-radius: 19px;
  ${({theme}) => theme.mediaQueries.md} {
    display: none;
  }
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  ${({theme}) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`

const HashBlock = styled.a`
  color: ${({theme}) => theme.colors.white};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 80%; /* 17.6px */
  letter-spacing: -0.88px;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

const InfoRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
  justify-content: flex-start;
  ${({theme}) => theme.mediaQueries.md} {
    align-items: center;
  }
`

const InfoText = styled.div`
  color: ${({theme}) => theme.colors.border};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  white-space: nowrap;
  ${({theme}) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

function TransactionComponent({symbol, transactionType, date, value, hash, chainId, client}: ITransaction) {
  const {usdPrices} = useAppState()
  const {walletData} = useWalletState()
  return (
    <Wrapper>
      <LeftBlock>
        <IconWrapper>
          <TransactionIcon/>
        </IconWrapper>
        <InfoBlock>
          {hash ? <HashBlock href={`${BASE_BSC_SCAN_URLS[chainId]}/transaction/${hash}`} target='_blank'
                      rel='noreferrer'>{shortAddress(hash, 8, -6)}</HashBlock>
          : <HashBlock href={`${BASE_BSC_SCAN_URLS[chainId]}/${client}`} target='_blank'
                       rel='noreferrer'>to {transactionType === 'withdraw' ? shortAddress(client, 8, -6) : ''}</HashBlock>}
          <InfoRow>
            <InfoText>
              {transactionType}
            </InfoText>
            <InfoText>
              {date}
            </InfoText>
          </InfoRow>
        </InfoBlock>
      </LeftBlock>
      <BalanceBlock>
        <Title>{`${value || 0}`.slice(0, 6)} {symbol}</Title>
        <SymbolBlock>
          ${symbol in usdPrices ? (Number(value) * usdPrices[symbol]).toFixed(2) : ''}
        </SymbolBlock>
      </BalanceBlock>
    </Wrapper>
  );
}

export default TransactionComponent;
