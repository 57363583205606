import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...props} >
          <path d="M14.5807 18.75L13.1224 17.2396L16.8203 13.5417H4.16406V11.4583H16.8203L13.1224 7.76042L14.5807 6.25L20.8307 12.5L14.5807 18.75Z" fill="white"/>
          <path d="M14.5807 18.75L13.1224 17.2396L16.8203 13.5417H4.16406V11.4583H16.8203L13.1224 7.76042L14.5807 6.25L20.8307 12.5L14.5807 18.75Z" fill="url(#paint0_linear_1249_1899)"/>
          <defs>
            <linearGradient id="paint0_linear_1249_1899" x1="17.7763" y1="6.25" x2="1.95022" y2="8.79669" gradientUnits="userSpaceOnUse">
              <stop stopColor="#4CA9FF"/>
              <stop offset="1" stopColor="#4C89FF"/>
            </linearGradient>
          </defs>
        </Svg>
    );

export default Icon;
