import { useEffect, useState } from 'react';
import {useWalletState} from "../store/wallet/hooks";
import {ChainId} from "../config";
import {useAppState} from "../store/app/hooks";

export default function useTotalBalance() {
  const {assets} = useWalletState()
  const {usdPrices} = useAppState()
  const [usdBalance, setUsdBalance] = useState(0);
  const [balanceLoaded, setBalanceLoaded] = useState(false);

  useEffect(() => {
    let loaded = true;
    for (const asset of assets) {
      if (asset.balance === undefined) {
        loaded = false;
      }
    }

    if (loaded) {
      (async () => {
        let newUsdBalance = 0
        const tonAssets = assets.filter(({chainId}) => chainId === ChainId.TON)

        if (tonAssets[0].balance && usdPrices.TON) {
          newUsdBalance += +(tonAssets[0].balance * usdPrices.TON).toFixed(4)
        }
        setUsdBalance(newUsdBalance);
        setBalanceLoaded(true);
      })();
    }
  }, [assets]);

  return {usdBalance, balanceLoaded};
}
