import {useEffect, useState} from "react";

function useBlockNumber(multiply = 1): number {
  const [blockNumber, setBlockNumber] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBlockNumber(prevState => prevState + 1)
    }, 15000 * multiply)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return blockNumber
}

export default useBlockNumber;
