import React from 'react';
import styled from "styled-components";
import {IAsset} from "../../config/assets";

const AssetRow = styled.div`
  width: 100%;
  display: flex;
  padding: 4px;
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.colors.input};
  }
`

const IconWrapper = styled.div`
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 50px;
  background: ${({theme}) => theme.colors.iconBg};
  overflow: hidden;
`

const AssetInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`

const AssetTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.white};
`
const AssetName = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.white};
  opacity: .5;
`

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

const BalanceBlock = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.white};
`

function AssetRowComponent({callback, asset, ...props}: { asset: IAsset, callback?: (...arg: any) => void }) {
  return (
    <AssetRow {...props} onClick={() => {
      if (callback) {
        callback()
      }
    }}>
      <InfoBlock>
        <IconWrapper>
          <img src={asset.logoURI} alt={asset.name}/>
        </IconWrapper>
        <AssetInfo>
          <AssetTitle>{asset.symbol}</AssetTitle>
          <AssetName>{asset.name}</AssetName>
        </AssetInfo>
      </InfoBlock>
      <BalanceBlock>
        {asset.balance} {asset.symbol.toUpperCase()}
      </BalanceBlock>
    </AssetRow>
  );
}

export default AssetRowComponent;
