import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {RootState} from "../index";
import {IAsset} from "../../config/assets";

const fetchAssetsChanges = createAsyncThunk<IAsset[],
  void,
  {
    state: RootState
  }>(
  'wallet/fetchAssetsChanges',
  async (_, ThunkApi) => {
    const {wallet: {walletData, assets}} = ThunkApi.getState()
    if (walletData && assets.length > 0) {
      const data: IAsset[] = []
      try {
        const res = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=tontoken&vs_currencies=usd&include_24hr_change=true`)
        for (const asset of assets) {
          const currentChange: number = res.data?.tontoken?.usd_24h_change
          data.push({
            ...asset,
            change: currentChange ? parseFloat(currentChange.toFixed(2)) : 0
          })
        }
        return data
      } catch (e) {
        console.log(e)
        return assets
      }
    }
    return assets
  }
)

export default fetchAssetsChanges
