import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {RootState} from "../index";
import {ITransaction} from "./types";
import {getTonTransactions} from "../../utils/ton";



const fetchTransactions = createAsyncThunk<ITransaction[],
  void,
  {
    state: RootState
  }>(
  'wallet/fetchTransactions',
  async (_, ThunkApi) => {
    const {wallet: {walletData, assets, transactions}} = ThunkApi.getState()
    if (walletData && assets.length > 0) {
      const data: ITransaction[] = []
      const txsTon = await getTonTransactions(walletData);
      for (const trx of txsTon) {
        data.push(trx)
      }

      const tokensData = []
      // try {
      //   for (const asset of assets.filter((item) => !!item.address)) {
      //     tokensData.push(getTokenTransactions(walletData.addr, asset, blockNumberBNB))
      //   }
      //   const tokensDataRes = await Promise.all(tokensData)
      //   for (const item of tokensDataRes) {
      //     for (const txs of item) {
      //       data.push(txs)
      //     }
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
      return data
    }
    return transactions
  }
)

export default fetchTransactions
