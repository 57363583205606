import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="23" height="23" viewBox="0 0 23 23" fill="none" {...props} >
            <path d="M15.5819 2.18848H7.6592C4.21784 2.18848 2.16626 4.24006 2.16626 7.68142V15.5947C2.16626 19.0455 4.21784 21.097 7.6592 21.097H15.5724C19.0138 21.097 21.0654 19.0455 21.0654 15.6041V7.68142C21.0748 4.24006 19.0233 2.18848 15.5819 2.18848ZM11.6205 15.311C9.59733 15.311 7.95228 13.666 7.95228 11.6428C7.95228 9.61955 9.59733 7.9745 11.6205 7.9745C13.6438 7.9745 15.2888 9.61955 15.2888 11.6428C15.2888 13.666 13.6438 15.311 11.6205 15.311ZM17.2175 6.80217C17.1702 6.91562 17.104 7.01962 17.0189 7.11416C16.9244 7.19925 16.8204 7.26543 16.707 7.3127C16.5935 7.35997 16.4706 7.38833 16.3477 7.38833C16.0924 7.38833 15.8561 7.29379 15.6764 7.11416C15.5913 7.01962 15.5252 6.91562 15.4779 6.80217C15.4306 6.68872 15.4023 6.56581 15.4023 6.44291C15.4023 6.32 15.4306 6.19709 15.4779 6.08364C15.5252 5.96074 15.5913 5.86619 15.6764 5.77165C15.8939 5.5542 16.2248 5.45021 16.5273 5.51639C16.5935 5.52584 16.6502 5.54475 16.707 5.57311C16.7637 5.59202 16.8204 5.62038 16.8771 5.6582C16.9244 5.68656 16.9717 5.73383 17.0189 5.77165C17.104 5.86619 17.1702 5.96074 17.2175 6.08364C17.2648 6.19709 17.2931 6.32 17.2931 6.44291C17.2931 6.56581 17.2648 6.68872 17.2175 6.80217Z" fill="white"/>
        </Svg>
    );

export default Icon;
