import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props} >
          <g opacity="0.4">
            <path d="M5.5237 17.3874C5.12648 16.7013 4.82856 15.9971 4.62995 15.2749C4.43134 14.5527 4.33203 13.8124 4.33203 13.0541C4.33203 10.6346 5.17161 8.57631 6.85078 6.87909C8.52995 5.18186 10.5793 4.33325 12.9987 4.33325H13.1883L11.4549 2.59992L12.9716 1.08325L17.3049 5.41659L12.9716 9.74992L11.4549 8.23325L13.1883 6.49992H12.9987C11.1931 6.49992 9.65842 7.13638 8.39453 8.40929C7.13064 9.68221 6.4987 11.2305 6.4987 13.0541C6.4987 13.5235 6.55286 13.9839 6.6612 14.4353C6.76953 14.8867 6.93203 15.3291 7.1487 15.7624L5.5237 17.3874ZM13.0258 24.9166L8.69245 20.5833L13.0258 16.2499L14.5424 17.7666L12.8091 19.4999H12.9987C14.8043 19.4999 16.339 18.8635 17.6029 17.5905C18.8668 16.3176 19.4987 14.7694 19.4987 12.9458C19.4987 12.4763 19.4445 12.0159 19.3362 11.5645C19.2279 11.1131 19.0654 10.6708 18.8487 10.2374L20.4737 8.61242C20.8709 9.29853 21.1688 10.0027 21.3674 10.7249C21.5661 11.4471 21.6654 12.1874 21.6654 12.9458C21.6654 15.3652 20.8258 17.4235 19.1466 19.1208C17.4674 20.818 15.4181 21.6666 12.9987 21.6666H12.8091L14.5424 23.3999L13.0258 24.9166Z" fill="white"/>
          </g>
        </Svg>
    );

export default Icon;
