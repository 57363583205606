import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
          <g opacity="0.53">
            <path d="M10 16.452V6.4614L15 11.4567L10 16.452Z" fill="white"/>
          </g>
        </Svg>
    );

export default Icon;
